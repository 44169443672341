<template>
  <div>
    <referral-rectangle
      class="referral-home-kr"
      :referral="referralSettings"
      :title="referralSettings.title"
      :description="referralSettings.description"
      :link="referralSettings.link"
    ></referral-rectangle>

    <redemption-rectangle
      class="reward-home-kr"
      :redemption="{
        title: `${this.$store.state.ew_translations.nextReward}:`,
        description: `${lastReward.title} - ${addComma(
          lastReward.requiredPts,
        )} ${this.$store.state.ew_translations.ptsLabel}`,
        pointsLeft: lastReward.pointsLeft,
        percentageLeft: lastReward.percentageLeft,
      }"
      :showArrow="true"
      svgKey="gift"
      @set-item="$emit('redeem', lastReward)"
      :showProgress="true"
      :percentageLeft="
        lastReward.pointsLeft > 0
          ? parseInt((lastReward.pointsLeft / lastReward.requiredPts) * 100)
          : 0
      "
    ></redemption-rectangle>
    <wte-rectangle
      :title="this.$store.state.ew_translations.wteTitle"
      :description="this.$store.state.ew_translations.wteDesc"
      :showArrow="true"
      svgKey="hand"
      @set-item="$emit('open-wte')"
    ></wte-rectangle>
  </div>
</template>

<script>
import { addComma } from "../utils/formatNumber";

import ReferralRectangle from "../components/ReferralRectangle.vue";
import RedemptionRectangle from "../components/RedemptionRectangle.vue";
import WteRectangle from "../components/WteRectangle.vue";

export default {
  name: "HomeTab",
  props: ["referralSettings", "lastReward"],
  emits: ["redeem", "open-wte"],
  components: {
    ReferralRectangle,
    RedemptionRectangle,
    WteRectangle,
  },
  methods: {
    addComma: addComma,
  },
};
</script>

<style scoped>
.referral-home-kr {
  margin-bottom: 17px;
}

.reward-home-kr {
  margin-bottom: 17px;
}
</style>