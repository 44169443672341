<template>
  <div>
    <div style="margin-left: 7px">
      <div class="title-kr">
        {{ this.$store.state.ew_translations.welcome }} {{ user.firstName }}
      </div>
      <div class="description-kr">
        {{ this.$store.state.ew_translations.youHave }}
        {{ addComma(user.points) }}
        {{ this.$store.state.ew_translations.ptsLabel }}
      </div>
      <div class="tier-kr">{{ user.tier }}</div>
    </div>
  </div>
</template>x

<script>
import { addComma } from "../../utils/formatNumber";
export default {
  name: "WelcomeTop",
  props: ["user"],
  methods: {
    addComma: addComma,
  },
};
</script>

<style scoped>
.title-kr {
  font-size: 20px;
  line-height: 18.4px;
  text-align: left;
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 22px;
}

.description-kr {
  font-size: 15px;
  line-height: 16px;
  text-align: left;
  margin-top: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tier-kr {
  font-size: 12px;
  line-height: 13px;
  text-align: left;
  margin-top: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>