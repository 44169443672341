<template>
  <div class="content-kr">
    <div class="history-container-kr">
      <transaction-rectangle
        class="hist-container"
        style="margin-bottom: 17px"
        v-for="transaction in transactionsArray"
        :key="transaction.id"
        :transaction="transaction"
      />
    </div>
  </div>
</template>

<script>
import { getSvg } from "../utils/svgLibrary";

import TransactionRectangle from "../components/TransactionRectangle.vue";

export default {
  name: "RewardsTab",
  components: {
    TransactionRectangle,
  },
  props: ["transactionsArray"],
  methods: {
    getSvg: getSvg,
  },
};
</script>

<style scoped>
.content-kr {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 7px;
  padding-right: 6px;
}

.hist-container:last-child {
  border-bottom-width: 0px !important;
}

.history-container-kr {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.pagination-loading-kr {
  display: flex;
  position: absolute;
  bottom: -3px;
  right: 3px;
  cursor: pointer;
}

.history-kr {
  display: flex;
  position: absolute;
  bottom: 1px;
  right: 15px;
}
</style>