import { createApp } from "vue";
// import * as Sentry from "@sentry/vue";
// import { Integrations } from "@sentry/tracing";
import App from "./App.vue";
import router from "./router";
import i18n from "./i18n";
import store from "./store";
import "./index.css";
import title from "./mixins/title";
import VueInputAutowidth from "vue-input-autowidth";

const app = createApp(App);

// Sentry.setUser({ email: store.state.user?.email || "null" });

// Sentry.init({
//   app,
//   dsn:
//     "https://6486f4789daa4cfab222e8ee086044dc@o1002968.ingest.sentry.io/6086239",
//   debug: process.env.NODE_ENV === "development",
//   release: process.env.PACKAGE_VERSION,
//   environment: process.env.NODE_ENV,
//   integrations: [
//     new Integrations.BrowserTracing({
//       routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//       tracingOrigins: [
//         "localhost",
//         "signup.traktrok.com",
//         "signup.kangaroorewards.com",
//         /^\//,
//       ],
//     }),
//   ],

//   tracesSampleRate: 1.0,
// });

app
  .use(store)
  .use(router)
  .use(i18n)
  .use(VueInputAutowidth)
  .mixin(title)
  .mount("#app");
