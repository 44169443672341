<template>
  <div id="ew_app" class="ew-widget" :style="cssAtts">
    <!-- widget starts -->
    <div class="widget-kr ew_bg-primary-kr main-div-kr">
      <notification v-if="this.$store.state.ew_notification.active" />
      <div class="sub-main-div-kr">
        <div
          id="close_widget_button"
          class="main-close-btn-kr"
          v-html="getSvg('#fff', 20, 20, 'roundedClose')"
        ></div>
        <!-- user logged in section starts -->
        <div class="sub-main-div-kr">
          <!-- top section starts -->
          <welcome-top
            v-if="activeTab != 99 && activeTab != 101"
            class="top-section-kr text-white"
            style="flex: 0 1 auto"
            :user="this.$store.state.ew_user"
          />
          <minimized-top
            @go-back="goTo"
            class="top-section-kr text-white"
            :user="this.$store.state.ew_user"
            v-if="activeTab == 99 || activeTab == 101"
          />
          <!-- top section ends -->

          <!-- nav bar starts -->
          <div
            v-if="activeTab != 99 && activeTab != 101"
            class="nav-bar-kr text-white"
            style=""
          >
            <!-- tab starts -->
            <div
              @click="tab.id != 4 ? changeTab(tab.id) : null"
              :class="[
                activeTab === tab.id && tab.id != 4
                  ? 'active-nav-kr'
                  : 'inactive-nav-kr ',
                'nav-kr',
              ]"
              v-for="tab in tabs.filter((t) => t.active)"
              :key="tab.id"
            >
              <div v-if="tab.icon" class="dropdown-kr ew_btn-primary-kr">
                <div
                  class="dropdown-btn-kr"
                  :id="tab.id"
                  v-html="getSvg('#fff', 11, 12.2, tab.name)"
                ></div>
                <div class="dropdown-content-kr ew_btn-ew_bg-primary-kr">
                  <div
                    @click="changeTab(4)"
                    class="dropdown-content-btn-kr"
                    style="border-bottom-width: 1px"
                  >
                    Profile
                  </div>

                  <div @click="changeTab(5)" class="dropdown-content-btn-kr">
                    History
                  </div>
                </div>
              </div>
              <div class="dot-nav-container-kr" v-else style="">
                <div
                  class="dot-nav-kr"
                  v-if="activeTab === tab.id"
                  v-html="getSvg('#fff', 6, 6, 'dot')"
                ></div>
                <div>{{ tab.name }}</div>
              </div>
            </div>

            <!-- tab ends -->
          </div>
          <!-- nav bar ends -->
          <div
            v-if="selectedTheme == 0"
            v-html="getSvg('#fff', 10, 10, 'line')"
            class="line-separator-svg-kr"
          ></div>
          <div v-if="selectedTheme == 1" class="wave-svg-kr">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="58"
              height="65"
              viewBox="0 0 58 65"
            >
              <g id="Page-1" transform="translate(0 0)">
                <g id="corner-with-pad">
                  <path
                    id="Combined-Shape"
                    d="M0,0C0,14.985,23.705,44.933,57.886,45H58V65H0V0Z"
                    fill="#fff"
                    fill-rule="evenodd"
                  />
                </g>
              </g>
            </svg>
          </div>
          <!-- main section starts  -->

          <div
            class="
              content-kr
              second-sub-div-kr
              ew_main-div-color-kr ew_text-primary-color-kr
            "
          >
            <transition name="fade">
              <home-tab
                class="tab-kr"
                v-if="activeTab === 1"
                @redeem="redeemAction"
                @open-wte="changeTab(6)"
                :referralSettings="this.$store.state.ew_referralSettings"
                :lastReward="
                  this.$store.state.offerSettings.filter(
                    (reward) => reward.active && reward.reward,
                  )[
                    this.$store.state.offerSettings.filter(
                      (reward) => reward.active && reward.reward,
                    ).length - 1
                  ]
                "
              />
              <div id="2" class="tab-kr" v-else-if="activeTab === 2">
                <redemptions-tab
                  :redemptionsArray="
                    this.$store.state.offerSettings.filter(
                      (redemption) => redemption.active && redemption.reward,
                    )
                  "
                  @redeem="redeemAction"
                />
              </div>
              <redemptions-tab
                class="tab-kr"
                v-else-if="activeTab === 3"
                :redemptionsArray="
                  this.$store.state.offerSettings.filter(
                    (redemption) => redemption.active && redemption.offer,
                  )
                "
                @redeem="redeemAction"
              />
              <div id="4" class="tab-kr" v-else-if="activeTab === 4">
                <settings-tab
                  class="tab-kr"
                  @open-transactions="changeTab(5)"
                  v-if="activeTab === 4"
                  :consentArray="this.$store.state.ew_consentList"
                  :selectedConsents="this.$store.state.ew_selectedConsents"
                />
              </div>
              <div id="5" class="tab-kr" v-else-if="activeTab === 5">
                <transactions-tab
                  class="tab-kr"
                  :transactionsArray="this.$store.state.ew_transactionsList"
                />
              </div>
              <wte-tab
                class="tab-kr"
                @set-wte="showWteInfo"
                v-else-if="activeTab === 6"
                :wtesArray="this.$store.state.ew_wteList"
              />
              <redemption-card
                class="tab-kr"
                style="width: 100%"
                v-else-if="activeTab === 99"
                :redemption="this.$store.state.ew_selectedRedemption"
              />
              <wte-card
                class="tab-kr"
                style="width: 100%"
                v-else-if="activeTab === 101"
                :wte="this.$store.state.ew_selectedwte"
              />
            </transition>
          </div>
          <!-- main section ends  -->
        </div>
        <!-- user logged in section ends -->
      </div>

      <!-- widget ends -->
    </div>
  </div>
</template>

<script>
import { getSvg } from "./utils/svgLibrary";
import { addComma } from "./utils/formatNumber";
import { replaceValues } from "./utils/formatString";
import WelcomeTop from "./components/TopSection/WelcomeTop.vue";
import MinimizedTop from "./components/TopSection/MinimizedTop.vue";
import HomeTab from "./views/HomeTab.vue";
import RedemptionsTab from "./views/RedemptionsTab.vue";
import SettingsTab from "./views/SettingsTab.vue";
import RedemptionCard from "./components/RedemptionCard.vue";
import TransactionsTab from "./views/TransactionsTab.vue";
import WteTab from "./views/WteTab.vue";
import WteCard from "./components/WteCard.vue";
import Notification from "./components/Notification.vue";
import "./index.css";
import "./animate.css";

export default {
  name: "New Widget",
  components: {
    WelcomeTop,
    MinimizedTop,
    HomeTab,
    RedemptionsTab,
    SettingsTab,
    RedemptionCard,
    TransactionsTab,
    WteTab,
    WteCard,
    Notification,
  },
  data() {
    return {
      showNotification: false,
      loadingMainData: true,
      tabs: [],
      activeTab: null,
      selectedTheme: 0,
      themeCssSetting: [
        {
          themeName: "Full",
          mainDivColor: "rgba(255,255,255,0)",
          txtPrimaryColor: "rgba(255,255,255,1)",
          iconPrimaryColor: "rgba(255,255,255,1)",
          progressBgPrimaryColor: "rgba(255,255,255,0.5)",
          iconBgPrimaryColor: "rgba(255,255,255,0.15)",
          btnBgPrimaryColor: "rgba(255,255,255,0.15)",
          btnPrimaryColor: "rgba(255,255,255,1)",
          slideFillColor: "rgba(255,255,255,1)",
          slideEmptyColor: "rgba(255,255,255,0.15)",
          valueBgPrimaryColor: "rgba(255,255,255,0.15)",
          valuePrimaryColor: "rgba(255,255,255,1)",
          borderPrimaryColor: "rgba(255,255,255,1)",
          paddingLeft: "8px",
          paddingRight: "8px",
          paddingBottom: "0px",
          paddingTop: "0px",
          marginLeft: "12px",
          marginRight: "12px",
          marginBottom: "12px",
          marginTop: "0px",
          borderTopRight: "12px",
          borderRadius: "12px",
          boxShadow: "0px 3px 20px #0000001a",
          borderBottomWidth: "0px",
          smallPadding: "0px",
          largePadding: "12px",
        },
        {
          themeName: "Wave",
          mainDivColor: "rgba(255,255,255,1)",
          txtPrimaryColor: "rgba(121,127,132,1)",
          iconPrimaryColor: "rgba(121,127,132,1)",
          progressBgPrimaryColor: "rgba(121,127,132,0.5)",
          iconBgPrimaryColor: "rgba(121,127,132,0.15)",
          btnBgPrimaryColor: "rgba(121,127,132,1)",
          btnPrimaryColor: "rgba(255,255,255,1)",
          slideFillColor: "rgba(121,127,132,1)",
          slideEmptyColor: "rgba(121,127,132,0.15)",
          valueBgPrimaryColor: "rgba(121,127,132,1)",
          valuePrimaryColor: "rgba(255,255,255,1)",
          borderPrimaryColor: "rgba(121,127,132,1)",
          paddingLeft: "20px",
          paddingRight: "20px",
          paddingBottom: "12px",
          paddingTop: "10px",
          marginLeft: "0px",
          marginRight: "0px",
          marginBottom: "0px",
          marginTop: "12px",
          borderTopRight: "48px",
          borderRadius: "12px",
          boxShadow: "0px 3px 20px #0000001a",
          borderBottomWidth: "0px",
          smallPadding: "0px",
          largePadding: "12px",
        },
        {
          themeName: "Card",
          mainDivColor: "rgba(255,255,255,1)",
          txtPrimaryColor: "rgba(121,127,132,1)",
          iconPrimaryColor: "rgba(121,127,132,1)",
          progressBgPrimaryColor: "rgba(121,127,132,0.5)",
          iconBgPrimaryColor: "rgba(121,127,132,0.15)",
          btnBgPrimaryColor: "rgba(121,127,132,1)",
          btnPrimaryColor: "rgba(255,255,255,1)",
          slideFillColor: "rgba(121,127,132,1)",
          slideEmptyColor: "rgba(121,127,132,0.15)",
          valueBgPrimaryColor: "rgba(121,127,132,1)",
          valuePrimaryColor: "rgba(255,255,255,1)",
          borderPrimaryColor: "rgba(121,127,132,1)",
          paddingLeft: "8px",
          paddingRight: "8px",
          paddingBottom: "0px",
          paddingTop: "10px",
          marginLeft: "12px",
          marginRight: "12px",
          marginBottom: "12px",
          marginTop: "12px",
          borderTopRight: "12px",
          borderRadius: "0px",
          boxShadow: "0",
          borderBottomWidth: "1px",
          smallPadding: "16px",
          largePadding: "20px",
        },
      ],
    };
  },

  computed: {
    cssAtts() {
      return {
        "--widget_height": `452px`,
        "--widget_width": `290px`,
        "--primary_color": `${this.$store.state.widgetPrimaryColor}`,
        "--primary_txt_color": `${this.$store.state.ew_settings.primaryTxtColor}`,
        "--main_bg_color": `${
          this.themeCssSetting[this.selectedTheme].mainDivColor
        }`,
        "--text_primary_color": `${
          this.themeCssSetting[this.selectedTheme].txtPrimaryColor
        }`,
        "--icon_primary_color": `${
          this.themeCssSetting[this.selectedTheme].iconPrimaryColor
        }`,
        "--icon_bg_primary_color": `${
          this.themeCssSetting[this.selectedTheme].iconBgPrimaryColor
        }`,
        "--btn_bg_primary_color": `${
          this.themeCssSetting[this.selectedTheme].btnBgPrimaryColor
        }`,
        "--btn_primary_color": `${
          this.themeCssSetting[this.selectedTheme].btnPrimaryColor
        }`,
        "--ew_slider-fill-primary-kr": `${
          this.themeCssSetting[this.selectedTheme].slideFillColor
        }`,
        "--ew_slider-empty-primary-kr": `${
          this.themeCssSetting[this.selectedTheme].slideEmptyColor
        }`,
        "--value_bg_primary_color": `${
          this.themeCssSetting[this.selectedTheme].valueBgPrimaryColor
        }`,
        "--value_primary_color": `${
          this.themeCssSetting[this.selectedTheme].valuePrimaryColor
        }`,
        "--border_primary_color": `${
          this.themeCssSetting[this.selectedTheme].borderPrimaryColor
        }`,
        "--margin_left": `${
          this.themeCssSetting[this.selectedTheme].marginLeft
        }`,
        "--margin_right": `${
          this.themeCssSetting[this.selectedTheme].marginRight
        }`,
        "--padding_left": `${
          this.themeCssSetting[this.selectedTheme].paddingLeft
        }`,
        "--padding_right": `${
          this.themeCssSetting[this.selectedTheme].paddingRight
        }`,
        "--padding_bottom": `${
          this.themeCssSetting[this.selectedTheme].paddingBottom
        }`,
        "--padding_top": `${
          this.themeCssSetting[this.selectedTheme].paddingTop
        }`,
        "--margin_top": `${this.themeCssSetting[this.selectedTheme].marginTop}`,
        "--margin_bottom": `${
          this.themeCssSetting[this.selectedTheme].marginBottom
        }`,
        "--border_top_right_radius": `${
          this.themeCssSetting[this.selectedTheme].borderTopRight
        }`,
        "--border_radius": `${
          this.themeCssSetting[this.selectedTheme].borderRadius
        }`,
        "--box_shadow": `${this.themeCssSetting[this.selectedTheme].boxShadow}`,
        "--border_bottom_width": `${
          this.themeCssSetting[this.selectedTheme].borderBottomWidth
        }`,
        "--small_padding": `${
          this.themeCssSetting[this.selectedTheme].smallPadding
        }`,
        "--large_padding": `${
          this.themeCssSetting[this.selectedTheme].largePadding
        }`,
      };
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    getSvg: getSvg,
    addComma: addComma,
    replaceValues: replaceValues,

    async fetchData() {
      try {
        this.selectedTheme = this.$store.state.selectedThemeWid;
        this.activeTab = 1;

        //mapping settings
        this.$store.commit("changeSettings", [
          {
            key: "txtPrimaryColor",
            value: this.themeCssSetting[this.selectedTheme].txtPrimaryColor,
          },
          {
            key: "iconPrimaryColor",
            value: this.themeCssSetting[this.selectedTheme].iconPrimaryColor,
          },
          {
            key: "progressBgPrimaryColor",
            value:
              this.themeCssSetting[this.selectedTheme].progressBgPrimaryColor,
          },
        ]);

        //setting tabs
        this.tabs = [
          {
            id: 1,
            name: "Home",
            icon: false,
            active: true,
          },
          {
            id: 2,
            name: "Rewards",
            icon: false,
            active: true,
          },
          {
            id: 3,
            name: "Offers",
            icon: false,
            active: true,
          },
          { id: 4, name: "settings", icon: true, active: true },
        ];

        //mapping transactions
      } catch (error) {
        console.log("Error: ", error);
      }
    },

    changeTab(id) {
      this.activeTab = id;
      if (id === 2 || id === 3) {
        this.$store.commit(
          "changeRedemptionType",
          id === 2 ? "reward" : "offer",
        );
      }
    },

    redeemAction(redemption, isPwp = false, isPwc = false) {
      console.log("REDEmm: ", redemption);
      this.$store.commit("setRedemption", redemption);
      this.$store.commit("redeemPwp", isPwp);
      this.$store.commit("redeemPwc", isPwc);
      this.activeTab = 99;
    },

    showWteInfo(way) {
      this.$store.commit("setWte", way);
      this.activeTab = 101;
    },

    goTo() {
      this.activeTab =
        this.activeTab == 101
          ? 6
          : this.activeTab == 5
          ? 4
          : this.$store.state.ew_redemptionType === "reward"
          ? 2
          : 3;
    },
  },
};
</script>

<style>
/* Last added */
.main-div-kr {
  border-radius: 16px !important;
  position: relative;
  overflow: hidden;
}

.sub-main-div-kr {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.main-close-btn-kr {
  position: absolute;
  top: 19.2px;
  right: 16px;
  cursor: pointer;
}

.top-section-kr {
  background-color: rgba(0, 0, 0, 0);
  padding-top: 20px;
  padding-left: 8px;
  padding-right: 8px;
  margin-left: 12px;
  margin-right: 12px;
}

.nav-bar-kr {
  background-color: rgba(0, 0, 0, 0);
  flex: 0 1 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 17px;
  height: auto;
  padding-left: 8px;
  padding-right: 8px;
  margin-left: 12px;
  margin-right: 12px;
}

.nav-kr {
  z-index: 1;
  margin-left: 4px;
  margin-right: 4px;
}

.dot-nav-container-kr {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.dot-nav-kr {
  padding-left: 4px;
  padding-right: 12px;
}

.active-nav-kr {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  cursor: default;
  transition: all linear 250ms;
}

.inactive-nav-kr {
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
  cursor: pointer;
  transition: all linear 200ms;
}

.dropdown-kr {
  position: relative;
  display: inline-block;
}

.dropdown-kr:hover .dropdown-btn-kr {
  background-color: rgba(255, 255, 255, 0.1);
}

.dropdown-btn-kr {
  border-style: solid;
  border-width: 1px;
  border-radius: 6px !important;
  padding: 4px;
}

.dropdown-content-kr {
  display: none;
  position: absolute;
  right: -5px;
  padding-left: 2px;
  padding-right: 2px;
  border-radius: 10px;
  padding-left: 4px;
  padding-right: 4px;
  z-index: 1;
}

.dropdown-kr:hover .dropdown-content-kr {
  display: block;
}

.dropdown-content-btn-kr {
  padding: 8px;
  border-color: rgba(255, 255, 255, 1);
  cursor: pointer;
}

.line-separator-svg-kr {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 22px;
  padding-left: 8px;
  padding-right: 8px;
}

.wave-svg-kr {
  margin-top: -36px;
  margin-bottom: -32px;
  padding-left: 0px;
  padding-right: 0px;
}

.content-kr {
  display: grid;
  grid-template-columns: 100%;
}

.second-sub-div-kr {
  border-radius: 16px;
  flex: 1 1 auto;
  justify-content: center;
  overflow-y: auto;
  padding-left: var(--padding_left);
  padding-right: var(--padding_right);
  padding-bottom: var(--padding_bottom);
  padding-top: var(--padding_top);
  margin-right: var(--margin_right);
  margin-left: var(--margin_left);
  margin-bottom: var(--margin_bottom);
  margin-top: var(--margin_top);
  border-top-right-radius: var(--border_top_right_radius) !important;
  position: relative;
}

.tab-kr {
  grid-row-start: 1;
  grid-column-start: 1;
}

.dev-version {
  position: absolute;
  bottom: 12px;
  left: 12px;
  padding: 2px;
  color: rgba(255, 255, 255, 1);
  background-color: rgba(59, 174, 211, 1);
  border-radius: 8px;
}

.guest-tab-kr {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.loader-container-kr {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
