<template>
  <div
    class="w-full h-full flex justify-center flex-col items-center align-middle"
    style="padding-bottom: 70px"
  >
    <div
      class="text-4xl font-extrabold text-sblue-600 text-center"
      style="font-family: Metropolis_semiBold"
    >
      {{ $t("welcome_title") }}
    </div>
    <div class="mb-9 mt-2 text-2xl text-sblue-300 text-center">
      {{ $t("welcome_message") }}
    </div>
    <div
      class="
        font-thin
        cursor-pointer
        text-2xl
        bg-sblue-400
        text-white text-center
        py-4
        px-12
        button_shadow
        rounded-lg
      "
      @click="handleSubmit"
    >
      {{ $t("get_started") }}
    </div>
  </div>
</template>

<script>
// import LogRocket from "logrocket";

export default {
  name: "Home",
  title: "Welcome to Kangaroo",
  components: {},
  beforeMount() {
    this.$store.commit("resetState");
  },

  mounted() {
    console.log("Env type: ", process.env.NODE_ENV);
    if (
      process.env.NODE_ENV == "production" &&
      (!this.$route.query.platform_id || !this.$route.query.cid)
    ) {
      window.location.href = "https://kangaroorewards.com/";
    }
    this.$store.commit("setBusiness", {
      business: { name: this.$route.query.store_name },
    });
    this.$store.commit("setUser", {
      user: {
        first_name: this.$route.query.contact_name,
        email: this.$route.query.email,
        phone: this.$route.query.phone,
        logged: false,
      },
    });
    this.$store.commit("setPlatformId", {
      id: this.$route.query.platform_id,
    });
    this.$store.commit("setCId", {
      id: this.$route.query.cid,
    });

    // LogRocket.init(`${process.env.VUE_APP_LOGROCKET_ID}`, {
    //   release: `${this.$store.getters.appVersion}`,
    // });

    // LogRocket.identify(this.$store.state.cid, {
    //   name: this.$store.state.user.first_name,
    // });

    console.log(this.$store.state.access_token);
    console.log("CID used: ", this.$store.state.cid);
  },
  methods: {
    async handleSubmit() {
      try {
        this.$router.push({ name: "About yourself" });
        this.$store.commit("incrementProgress", { progress: 10 });
      } catch (error) {
        console.log("Error: " + error);
      }
    },
  },
};
</script>

<style scoped>
@font-face {
  font-family: "KaushanScript";
  src: local("KaushanScript"),
    url("../assets/fonts/KaushanScript-Regular.ttf") format("truetype");
}

.button_shadow {
  box-shadow: 0px 20px 50px #75bdd133;
}
</style>
