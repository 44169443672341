<template>
  <div class="ew_text-primary-kr main-kr">
    <!-- checkbox + message starts -->
    <label class="container-kr">
      {{ description }}
      <input
        style="cursor: pointer"
        :value="inputValue"
        v-model="model"
        type="checkbox" />
      <span class="checkmark-kr"></span
    ></label>
    <!-- checkbox + message ends -->
  </div>
</template>

<script>
export default {
  name: "ConsentCheck",
  props: ["description", "inputValue", "value"],
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>

<style scoped>
.main-kr {
  display: flex;
  text-align: left;
  font-size: 12px;
  line-height: 12px;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}

.container-kr {
  display: block;
  position: relative;
  margin-top: 3px;
  padding-left: 25px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container-kr input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark-kr {
  border-radius: 20% !important;
  position: absolute;
  top: 0;
  left: 0;
  height: 17px;
  width: 17px;
  border: 1px solid var(--border_primary_color);
}

/* On mouse-over, add a white background color */
.container-kr:hover input ~ .checkmark-kr {
  background-color: rgba(255, 255, 255, 0.1);
}

/* When the checkbox is checked, add a white background */
.container-kr input:checked ~ .checkmark-kr {
  background-color: rgba(255, 255, 255, 0.1);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark-kr:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container-kr input:checked ~ .checkmark-kr:after {
  display: block;
}

/* Style the checkmark/indicator */
.container-kr .checkmark-kr:after {
  left: 4.5px;
  top: 0.4px;
  width: 5px;
  height: 10px;
  border: solid var(--icon_primary_color);
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
</style>