import { createWebHistory, createRouter } from "vue-router";

import store from "../store";
import Home from "../views/Home.vue";
import About from "../views/About.vue";
import Qualification from "../views/Qualification.vue";

const routes = [
  { path: "/", name: "Home", component: Home },
  {
    path: "/about-yourself",
    name: "About yourself",
    component: About,
  },
  {
    path: "/about-business",
    name: "About business",
    component: Qualification,
  },
  {
    path: "/customization-steps",
    name: "Customization steps",
    component: () => import("../views/MainSteps.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/rules-setup",
    name: "Rules setup",
    component: () => import("../views/RulesSetup.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/referral-program-setup",
    name: "Referral program setup",
    component: () => import("../views/ReferralSetup.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/rewards-setup",
    name: "Rewards setup",
    component: () => import("../views/RewardsSetup.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/offers-setup",
    name: "Offers setup",
    component: () => import("../views/OffersSetup.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/packages-subscription",
    name: "Packages subscription",
    component: () => import("../views/Packages.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/loyalty-program-setup",
    name: "Loyalty program setup",
    component: () => import("../views/LoyaltyPrgCustomization.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/loyalty-page-setup",
    name: "Loyalty page setup",
    component: () => import("../views/LPCustomization.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/widget-theme-selection",
    name: "Widget theme selection",
    component: () => import("../views/WidgetThemeList.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/widget-customization",
    name: "Widget customization",
    component: () => import("../views/WidgetCustomization.vue"),
    meta: { requiresAuth: true },
  },
];

const routerOptions = {
  history: createWebHistory(process.env.BASE_URL),
  routes,
};

const router = createRouter(routerOptions);

if (window.location.hostname !== "localhost") {
  router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
      if (!store.state.user.logged) {
        next({
          name: "Home",
        });
      } else {
        next();
      }
    } else {
      next();
    }
  });
}

export default router;
