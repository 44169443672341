<template>
  <Layout>
    <router-view />

    <div v-if="!isProduction" class="w-full block left-3 fixed bottom-3">
      <span class="bg-sblue-400 p-1 text-white rounded-lg">v(0.5.3)</span>
    </div>
  </Layout>
</template>
<script>
import Layout from "@/layouts/Layout.vue";

export default {
  name: "App",

  components: {
    Layout,
  },
  data() {
    return {
      isProduction: process.env.NODE_ENV === "production",
    };
  },
  mounted() {
    console.log(this.$store.getters.appVersion);
    this.$store.commit("resetDataError");
  },
  updated() {
    // console.log(this.$store.state.access_token);
  },
};
</script>

<style>
@font-face {
  font-family: "Metropolis";
  src: local("Metropolis"),
    url("./assets/fonts/Metropolis-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Metropolis_medium";
  src: local("Metropolis"),
    url("./assets/fonts/Metropolis-Medium.otf") format("opentype");
}

@font-face {
  font-family: "Metropolis_bold";
  src: local("Metropolis"),
    url("./assets/fonts/Metropolis-Bold.otf") format("opentype");
  font-weight: bold;
}

@font-face {
  font-family: "Metropolis_semiBold";
  src: local("Metropolis"),
    url("./assets/fonts/Metropolis-SemiBold.otf") format("opentype");
  font-weight: bold;
}

@font-face {
  font-family: "Metropolis_boldItalic";
  src: local("Metropolis"),
    url("./assets/fonts/Metropolis-BoldItalic.otf") format("opentype");
  font-weight: bold;
  font-style: italic;
}

body {
  font-family: "Metropolis" !important;
  height: 100%;
}

#app {
  width: 100%;
  min-width: 100%;
  height: 100%;
  min-height: 100%;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  position: relative;
}
</style>
