export default {
  "languages": {
    "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anglais"])},
    "french": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Français"])}
  },
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hello i18n !!"])},
  "welcome_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenu à Kangaroo"])},
  "welcome_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let's setup your account in a few simple steps"])},
  "get_started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get Started"])},
  "about_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First things first"])},
  "about_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tell us about yourself"])},
  "first_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What's your first name?"])},
  "second_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What's the name of your store?"])},
  "email_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What's your email address?"])},
  "phone_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What's your phone number?"])},
  "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
  "setup_tile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perfect! Let's set up your program"])},
  "setup_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kangaroo has all the tools you need"])},
  "first_step_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let's customize your program to match your brand!"])},
  "second_step_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let's set up your referral program!"])},
  "third_step_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let's configure how you reward your customers!"])},
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
  "sdk_customize_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let's customize your loyalty program"])},
  "sdk_customize_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Which type of integration will you use?"])},
  "widget": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Widget"])},
  "loyalty_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loyalty Page"])},
  "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
  "widget_customize_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excellent! Our widget is easy to customize"])},
  "widget_customize_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have 3 styles for you to choose from"])},
  "full": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full"])},
  "wave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wave"])},
  "card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Card"])},
  "customize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customize"])},
  "widget_customize1_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customize your widget"])},
  "widget_customize1_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configure your widget to match your brand"])},
  "background": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Background"])},
  "widget_launcher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Widget Launcher"])},
  "img_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload image"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
  "referral_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referral Program"])},
  "referral_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leverage your best customers to attract new ones"])},
  "referral1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My ambassadors will earn"])},
  "referral2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New customers will earn"])},
  "referral3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referral must be completed in"])},
  "points": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["points"])},
  "points_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pts"])},
  "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["days"])},
  "reward_customers_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How do you want to reward your customers?"])},
  "reward_customers_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Points are the heart of your loyalty program"])},
  "reward_customers1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I want to give my customers"])},
  "reward_customers2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whenever they spend"])},
  "dollar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dollar"])},
  "offers_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["with Kangaroo, it's easy to keep things interesting"])},
  "offers_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here are few ideas to get you started"])},
  "end_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You're all set"])},
  "end_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You're on the FREE plan , but you can unlock more features."])},
  "finish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finish"])}
}