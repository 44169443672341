<template>
  <div @click="$emit('set-item')" class="main-kr ew_border-primary-kr">
    <!-- icon starts -->
    <div class="icon-parent-kr">
      <div class="icon-container-kr ew_icon-ew_bg-primary-kr">
        <div
          class="icon-kr"
          v-html="
            getSvg(
              this.$store.state.ew_settings.iconPrimaryColor,
              '15',
              '15',
              svgKey,
            )
          "
        ></div>
      </div>
    </div>
    <!-- icon ends -->
    <!-- title + description starts -->
    <div class="body-kr">
      <div class="title-kr">
        {{ title }}
      </div>
      <div class="description-kr" v-html="description"></div>
    </div>
    <!-- title + description ends -->
    <!-- arrow starts -->
    <div
      class="arrow-kr"
      style=""
      v-if="showArrow"
      v-html="
        getSvg(this.$store.state.ew_settings.iconPrimaryColor, 12, 12, 'arrow')
      "
    ></div>
    <!-- arrow ends -->
    <div></div>
  </div>
</template>

<script>
import { getSvg } from "../utils/svgLibrary";
export default {
  name: "WteRectangle",
  props: ["title", "description", "showArrow", "svgKey"],
  emits: ["set-item"],
  methods: {
    getSvg: getSvg,
  },
};
</script>

<style scoped>
.main-kr {
  background-color: rgba(255, 255, 255, 0.15);
  min-height: 56px;
  height: auto;
  width: 100%;
  display: flex;
  border-radius: var(--border_radius);
  align-items: center;
  cursor: pointer;
  border-bottom-width: var(--border_bottom_width);
  box-shadow: var(--box_shadow);
  padding-bottom: var(--small_padding);
}

.icon-container-kr {
  height: 36px;
  width: 36px;
  border-radius: 9999px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.icon-parent-kr {
  padding-left: 12px;
  padding-right: 12px;
  width: 22%;
}

.icon-kr {
  height: 30px;
  width: 30px;
  background-color: var(--icon_background_color);
  position: absolute;
  border-radius: 9999px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-shrink: 0;
}

.body-kr {
  text-align: left;
  overflow: hidden;
  height: 100%;
  width: 87%;
}

.title-kr {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  height: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 8px;
}

.description-kr {
  font-size: 12px;
  line-height: 16px;
  font-weight: 300;
  height: 50%;
  display: flex;
  justify-content: flex-start;
  justify-content: flex-start;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-bottom: 4px;
  margin-left: 8px;
}

.arrow-kr {
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
  margin-right: 16px;
  width: 8%;
}
</style>