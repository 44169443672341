<template>
  <div class="main-kr">
    <!-- image and social button starts -->
    <div class="image-icon-container-kr">
      <div
        :class="[
          this.$store.state.ew_selectedRedemption.pointsLeft &&
          this.$store.state.ew_selectedRedemption.pointsLeft >= 0
            ? 'not-redeemable-img-container-kr'
            : 'redeemable-img-container-kr',
          'image-container-kr ew_border-primary-kr',
        ]"
      >
        <img :src="getImgUrl(this.$store.state.ew_selectedRedemption.imgUrl)" />
      </div>
      <div class="icon-container-kr" style="">
        <div
          class="ew_border-primary-kr icon-kr"
          style=""
          v-html="
            getSvg(
              this.$store.state.ew_settings.iconPrimaryColor,
              13.63,
              13.57,
              'facebook',
            )
          "
        ></div>
        <div
          class="ew_border-primary-kr icon-kr"
          v-html="
            getSvg(
              this.$store.state.ew_settings.iconPrimaryColor,
              12.748,
              10.359,
              'twitter',
            )
          "
        ></div>
        <div
          class="ew_border-primary-kr icon-kr"
          v-html="
            getSvg(
              this.$store.state.ew_settings.iconPrimaryColor,
              10.36,
              12,
              'copy',
            )
          "
        ></div>
      </div>
    </div>
    <!-- image and social button ends -->
    <!-- description starts -->
    <div class="description-kr">
      {{ this.$store.state.ew_selectedRedemption.description }}
    </div>
    <!-- description ends -->
    <!-- redeem button starts -->
    <div
      @click="redeemable ? $emit('redeem') : null"
      :class="[
        this.$store.state.ew_minorLoading
          ? 'loading-btn-kr'
          : redeemable
          ? ''
          : 'not-redeemable-btn-kr',
        'btn-kr ew_btn-ew_bg-primary-kr ew_btn-primary-kr',
      ]"
      v-html="
        this.$store.state.ew_minorLoading
          ? `<div class='btn-kr-loader'></div>`
          : this.$store.state.ew_selectedRedemption.offer &&
            this.$store.state.ew_selectedRedemption.type == 'multiplier'
          ? this.$store.state.ew_translations.appliedToCheckout
          : !this.$store.state.ew_selectedRedemption.pointsLeft ||
            this.$store.state.ew_selectedRedemption.pointsLeft <= 0
          ? this.$store.state.ew_translations.redeemNow
          : `${this.$store.state.ew_selectedRedemption.pointsLeft} ${this.$store.state.ew_translations.ptsLabel} ${this.$store.state.ew_translations.left}`
      "
    ></div>
    <!-- redeem button ends -->
  </div>
</template>

<script>
import { getSvg } from "../../utils/svgLibrary";

export default {
  name: "RedemptionInfo",
  emits: ["redeem"],
  data() {
    return {
      redeemable: true,
    };
  },
  mounted() {
    console.log("LOLOL: ", this.$store.state.ew_selectedRedemption);
    // this.$store.commit("changeTab", 1);
    if (
      this.$store.state.ew_selectedRedemption.offer &&
      this.$store.state.ew_selectedRedemption.type == "multiplier"
    ) {
      this.redeemable = false;
    } else {
      this.redeemable = false;
    }
  },
  methods: {
    getSvg: getSvg,
    getImgUrl(url) {
      console.log("URL: ", url);
      var images = require.context(
        "../../../../assets/images",
        false,
        /\.png$/,
      );
      return images("./" + url + ".png");
    },
  },
};
</script>

<style scoped>
.main-kr {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.image-icon-container-kr {
  display: flex;
  align-items: stretch;
}

.image-container-kr {
  padding: 0.1px;
  border-radius: 8px !important;
  overflow: hidden;
  border-width: 2px;
}

.not-redeemable-img-container-kr {
  border-style: dashed;
}

.redeemable-img-container-kr {
  border-style: solid;
}

.icon-container-kr {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-left: 4px;
}

.icon-kr {
  border-radius: 6px !important;
  border-width: 1px;
  border-style: solid;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-bottom: 4px;
  padding: 8px;
}

.icon-container-kr:last-child {
  margin-bottom: 0px;
}

.description-kr {
  margin-top: auto;
  margin-bottom: auto;
  font-size: 12px;
  line-height: 16px;
  text-align: left;
}

.not-redeemable-btn-kr {
  cursor: not-allowed;
}
</style>