<template>
  <div class="main-kr">
    <redemption-rectangle
      style="margin-bottom: 17px"
      v-if="this.$store.state.ew_redemptionType === 'reward'"
      :redemption="{
        title: this.$store.state.ew_translations.pwcTitle,
        description: this.$store.state.ew_translations.pwcDesc,
        percentageLeft: '0',
      }"
      :showArrow="true"
      :showProgress="true"
      @set-item="$emit('redeem', 22, false, true)"
    ></redemption-rectangle>
    <redemption-rectangle
      style="margin-bottom: 17px"
      v-if="this.$store.state.ew_redemptionType === 'reward'"
      :redemption="{
        title: this.$store.state.ew_translations.pwpTitle,
        description: this.$store.state.ew_translations.pwpDesc,
        percentageLeft: '0',
      }"
      :showProgress="true"
      :showArrow="true"
      @set-item="$emit('redeem', 11, true)"
    ></redemption-rectangle>
    <redemption-rectangle
      class="red-container"
      style="margin-bottom: 17px"
      v-for="redemption in redemptionsArray"
      :key="redemption.id"
      :redemption="{
        ...redemption,

        title: redemption.showingTitle,
        description: redemption.expiryDate
          ? `expires at ${moment(redemption.expiryDate).lang('en').format('L')}`
          : null,
        type: redemption.typeId,
      }"
      :showArrow="true"
      :showProgress="progress"
      @set-item="$emit('redeem', redemption)"
    ></redemption-rectangle>
    <div v-if="redemptionsArray.length <= 0" class="empty-msg">
      {{ this.$store.state.ew_translations.noOffer }}
    </div>
  </div>
</template>

<script>
import moment from "moment";
import RedemptionRectangle from "../components/RedemptionRectangle.vue";
export default {
  name: "RewardsTab",
  components: {
    RedemptionRectangle,
  },

  props: ["redemptionsArray"],
  emits: ["redeem"],
  data() {
    return {
      progress: false,
    };
  },
  mounted() {
    console.log("Redemption: ", this.redemptionsArray);
    this.progress = this.$store.state.ew_redemptionType === "reward";
  },
  updated() {
    this.progress = this.$store.state.ew_redemptionType === "reward";
  },
  methods: {
    moment: moment,
  },
};
</script>

<style scoped>
.main-kr {
  padding-right: 4px;
  width: 100%;
  overflow: hidden;
  flex-direction: column;
  overflow-y: auto;
}

.empty-msg {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 40px;
}

.red-container:last-child {
  border-bottom-width: 0px !important;
}
</style>