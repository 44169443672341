export const getTitle = (key) => {
  switch (key) {
    case 4:
      return "reward";
    case 5:
      return "redemption";
    case 15:
      return "facebook share";
    case 16:
      return "twitter share";
    case 33:
      return "sign up bonus";
    case 58:
      return "points transfer";
    case 59:
      return "points deposit";
    case 64:
      return "points refund";
    default:
      break;
  }
};

export const getValidIds = () => {
  return [4, 5, 15, 16, 33, 58, 59, 64];
};

export const getDatePref = (key) => {
  switch (key) {
    case 4 || 15 || 16 || 33:
      return "earned on <br /> ";
    case 5:
      return "redeemed on <br /> ";
    case 58:
      return "transferred on <br /> ";
    case 59:
      return "deposited on <br /> ";
    case 64:
      return "refunded on <br /> ";
    default:
      break;
  }
};

export const getSvg = (color, width, height, key) => {
  switch (key) {
    case 4:
      return `   <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            width=${width}
            height=${height}
            viewBox="0 0 24 24"
          >
            <path
              d="M9.06,1.93C7.17,1.92 5.33,3.74 6.17,6H3A2,2 0 0,0 1,8V10A1,1 0 0,0 2,11H11V8H13V11H22A1,1 0 0,0 23,10V8A2,2 0 0,0 21,6H17.83C19,2.73 14.6,0.42 12.57,3.24L12,4L11.43,3.22C10.8,2.33 9.93,1.94 9.06,1.93M9,4C9.89,4 10.34,5.08 9.71,5.71C9.08,6.34 8,5.89 8,5A1,1 0 0,1 9,4M15,4C15.89,4 16.34,5.08 15.71,5.71C15.08,6.34 14,5.89 14,5A1,1 0 0,1 15,4M2,12V20A2,2 0 0,0 4,22H20A2,2 0 0,0 22,20V12H13V20H11V12H2Z"
              fill=${color}
            />
          </svg>`;
    case 5:
      return `       <svg
        xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            width=${width}
            height=${height}
            viewBox="0 0 24 24"
          >
            <path
              d="M22 10.92L19.26 9.33C21.9 7.08 19.25 2.88 16.08 4.31L15.21 4.68L15.1 3.72C15 2.64 14.44 1.87 13.7 1.42C12.06 .467 9.56 1.12 9.16 3.5L6.41 1.92C5.45 1.36 4.23 1.69 3.68 2.65L2.68 4.38C2.4 4.86 2.57 5.47 3.05 5.75L10.84 10.25L12.34 7.65L14.07 8.65L12.57 11.25L20.36 15.75C20.84 16 21.46 15.86 21.73 15.38L22.73 13.65C23.28 12.69 22.96 11.47 22 10.92M12.37 5C11.5 5.25 10.8 4.32 11.24 3.55C11.5 3.07 12.13 2.91 12.61 3.18C13.38 3.63 13.23 4.79 12.37 5M17.56 8C16.7 8.25 16 7.32 16.44 6.55C16.71 6.07 17.33 5.91 17.8 6.18C18.57 6.63 18.42 7.79 17.56 8M20.87 16.88C21.28 16.88 21.67 16.74 22 16.5V20C22 21.11 21.11 22 20 22H4C2.9 22 2 21.11 2 20V11H10.15L11 11.5V20H13V12.65L19.87 16.61C20.17 16.79 20.5 16.88 20.87 16.88Z"
              fill=${color}
            />
          </svg>`;
    case 15:
      return `  <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            width=${width}
            height=${height}
            viewBox="0 0 24 24"
          >
            <path
              d="M12 2.04C6.5 2.04 2 6.53 2 12.06C2 17.06 5.66 21.21 10.44 21.96V14.96H7.9V12.06H10.44V9.85C10.44 7.34 11.93 5.96 14.22 5.96C15.31 5.96 16.45 6.15 16.45 6.15V8.62H15.19C13.95 8.62 13.56 9.39 13.56 10.18V12.06H16.34L15.89 14.96H13.56V21.96A10 10 0 0 0 22 12.06C22 6.53 17.5 2.04 12 2.04Z"
              fill=${color}
            />
          </svg>`;
    case 16:
      return `<svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            width=${width}
            height=${height}
            viewBox=$"0 0 24 24"
          >
            <path
              d="M22.46,6C21.69,6.35 20.86,6.58 20,6.69C20.88,6.16 21.56,5.32 21.88,4.31C21.05,4.81 20.13,5.16 19.16,5.36C18.37,4.5 17.26,4 16,4C13.65,4 11.73,5.92 11.73,8.29C11.73,8.63 11.77,8.96 11.84,9.27C8.28,9.09 5.11,7.38 3,4.79C2.63,5.42 2.42,6.16 2.42,6.94C2.42,8.43 3.17,9.75 4.33,10.5C3.62,10.5 2.96,10.3 2.38,10C2.38,10 2.38,10 2.38,10.03C2.38,12.11 3.86,13.85 5.82,14.24C5.46,14.34 5.08,14.39 4.69,14.39C4.42,14.39 4.15,14.36 3.89,14.31C4.43,16 6,17.26 7.89,17.29C6.43,18.45 4.58,19.13 2.56,19.13C2.22,19.13 1.88,19.11 1.54,19.07C3.44,20.29 5.7,21 8.12,21C16,21 20.33,14.46 20.33,8.79C20.33,8.6 20.33,8.42 20.32,8.23C21.16,7.63 21.88,6.87 22.46,6Z"
              fill=${color}
            />
          </svg>`;
    case 33:
      return ` <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            width=${width}
            height=${height}
            viewBox="0 0 24 24"
          >
            <path
              d="M15,14C12.33,14 7,15.33 7,18V20H23V18C23,15.33 17.67,14 15,14M15,12A4,4 0 0,0 19,8A4,4 0 0,0 15,4A4,4 0 0,0 11,8A4,4 0 0,0 15,12M5,13.28L7.45,14.77L6.8,11.96L9,10.08L6.11,9.83L5,7.19L3.87,9.83L1,10.08L3.18,11.96L2.5,14.77L5,13.28Z"
              fill=${color}
            />
          </svg>`;
    case 58 || 59 || 64:
      return `<svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            width=${width}
            height=${height}
            viewBox="0 0 24 24"
          >
            <path
              d="M21,18V19A2,2 0 0,1 19,21H5C3.89,21 3,20.1 3,19V5A2,2 0 0,1 5,3H19A2,2 0 0,1 21,5V6H12C10.89,6 10,6.9 10,8V16A2,2 0 0,0 12,18M12,16H22V8H12M16,13.5A1.5,1.5 0 0,1 14.5,12A1.5,1.5 0 0,1 16,10.5A1.5,1.5 0 0,1 17.5,12A1.5,1.5 0 0,1 16,13.5Z"
              fill=${color}
            />
          </svg>`;
    case 100:
      return `            <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width=${width}
                        height=${height}
                        viewBox="0 0 45.892 45.892"
                      >
                        <path
                          id="Path_85"
                          data-name="Path 85"
                          d="M43.3,2a4.589,4.589,0,0,1,4.589,4.589V34.124A4.589,4.589,0,0,1,43.3,38.714H11.178L2,47.892V6.589A4.588,4.588,0,0,1,6.589,2H43.3M6.589,6.589v30.22l2.685-2.685H43.3V6.589H6.589m4.589,6.884H38.714v4.589H11.178V13.473m0,9.178H31.83v4.589H11.178Z"
                          transform="translate(-2 -2)"
                          fill=${color}
                        />
                      </svg>`;
    case 200:
      return `<svg xmlns="http://www.w3.org/2000/svg" 
        width=${width} 
        height=${height}
        viewBox="0 0 46 36.8">
                      <path
                       id="email-outline" 
                       d="M48,8.6A4.614,4.614,0,0,0,43.4,4H6.6A4.614,4.614,0,0,0,2,8.6V36.2a4.614,4.614,0,0,0,4.6,4.6H43.4A4.614,4.614,0,0,0,48,36.2V8.6m-4.6,0L25,20.1,6.6,8.6H43.4m0,27.6H6.6v-23L25,24.7,43.4,13.2Z" transform="translate(-2 -4)" 
                       fill=${color}
                       />
                    </svg>
                    `;
    case 9999:
      return `  <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width=${width}
                      height=${height}
                      viewBox="0 0 10.364 12"
                    >
                      <path
                        id="Path_28"
                        data-name="Path 28"
                        d="M11.273,11.909h-6V4.273h6m0-1.091h-6A1.091,1.091,0,0,0,4.182,4.273v7.636A1.091,1.091,0,0,0,5.273,13h6a1.091,1.091,0,0,0,1.091-1.091V4.273a1.091,1.091,0,0,0-1.091-1.091M9.636,1H3.091A1.091,1.091,0,0,0,2,2.091V9.727H3.091V2.091H9.636Z"
                        transform="translate(-2 -1)"
                        fill=${color}
                        opacity="0.8"
                      />
                    </svg>
                        `;
    default:
      return `       <svg
        xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            width=${width}
            height=${height}
            viewBox="0 0 24 24"
          >
            <path
              d="M22 10.92L19.26 9.33C21.9 7.08 19.25 2.88 16.08 4.31L15.21 4.68L15.1 3.72C15 2.64 14.44 1.87 13.7 1.42C12.06 .467 9.56 1.12 9.16 3.5L6.41 1.92C5.45 1.36 4.23 1.69 3.68 2.65L2.68 4.38C2.4 4.86 2.57 5.47 3.05 5.75L10.84 10.25L12.34 7.65L14.07 8.65L12.57 11.25L20.36 15.75C20.84 16 21.46 15.86 21.73 15.38L22.73 13.65C23.28 12.69 22.96 11.47 22 10.92M12.37 5C11.5 5.25 10.8 4.32 11.24 3.55C11.5 3.07 12.13 2.91 12.61 3.18C13.38 3.63 13.23 4.79 12.37 5M17.56 8C16.7 8.25 16 7.32 16.44 6.55C16.71 6.07 17.33 5.91 17.8 6.18C18.57 6.63 18.42 7.79 17.56 8M20.87 16.88C21.28 16.88 21.67 16.74 22 16.5V20C22 21.11 21.11 22 20 22H4C2.9 22 2 21.11 2 20V11H10.15L11 11.5V20H13V12.65L19.87 16.61C20.17 16.79 20.5 16.88 20.87 16.88Z"
              fill=${color}
            />
          </svg>`;
  }
};
