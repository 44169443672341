<template>
  <div
    class="flex w-full h-full flex-col justify-center"
    style="padding-left: 88px; padding-right: 88px"
  >
    <div
      class="text-left text-sblue-600 font-bold text-3xl"
      style="font-family: Metropolis_semiBold"
    >
      Let us help you pick a plan that suits your needs, we just need some
      information from you.
    </div>
    <form
      style="font-family: Metropolis_medium"
      id="qualification_form"
      @submit.prevent="handleSubmit"
    >
      <div
        class="
          mt-2
          md:mt-0
          flex
          items-end
          flex-col
          justify-center
          md:flex-row
          w-full
          md:space-x-8
          space-y-2
          md:space-y-12
          mb-2
          md:mb-4
          text-lg
        "
      >
        <div class="w-full lg:w-1/2">
          <label class="text-sgray-100"
            >What is your estimated monthly number of orders?</label
          ><br />
          <input
            v-model="numOrders"
            title="Please enter your estimated monthly number of orders on e-commerce"
            id="num_orders"
            type="Number"
            class="form_input_krssu rounded-xl"
            placeholder="10"
            style="height: 60px"
          />
        </div>
        <div class="w-full lg:w-1/2">
          <label class="text-sgray-100" style="line-height: 29px">
            What is your estimated annual revenue? </label
          ><br />
          <select
            title="Please enter your estimated annual revenue."
            v-model="annualRevenue"
            id="annual_revenue"
            type="select"
            class="form_input_krssu rounded-xl"
            style="height: 60px"
          >
            <option value="$0-$1m">$0-$1m</option>
            <option value="$1-$5m">$1-$5m</option>
            <option value="$5-$10m">$5-$10m</option>
            <option value="$0-$1m">$10-$50m</option>
            <option value="$0-$1m">$50m+</option>
          </select>
        </div>
      </div>
      <div
        class="
          mt-2
          md:mt-0
          flex
          items-end
          flex-col
          justify-start
          align-middle
          md:flex-row
          w-full
          md:space-x-8
          space-y-2
          md:space-y-12
          mb-2
          md:mb-4
          text-lg
        "
      >
        <div class="w-full lg:w-1/2">
          <label class="text-sgray-100" style="line-height: 29px">
            How many sites do you have? </label
          ><br />

          <input
            id="num_sites"
            title="Please enter the number of sites."
            v-model="numSites"
            type="number"
            class="form_input_krssu rounded-xl"
            placeholder="2"
            style="height: 60px"
          />
        </div>
      </div>

      <button
        type="submit"
        class="
          mt-14
          md:mt-24
          bg-sblue-400
          text-white text-center
          mx-auto
          button_shadow
          text-xl
          py-4
          px-24
          rounded-lg
          flex
          justify-center
          items-center
          cursor-pointer
          font-thin
          button_shadow
        "
        @click="checkError"
      >
        <div>
          {{ $t("next") }}
        </div>
        <div class="ml-4" v-html="getSvg('#fff', 18, 18, 'forwardArrow')"></div>
      </button>
    </form>
  </div>
</template>

<script>
// import Button from "@/components/Button.vue";

import { getSvg } from "../utils/getSvg";
import { getColors } from "../utils/getColors";
export default {
  name: "About business",
  title: "Tell us about your business",

  data() {
    return {
      formValues: {},
      colors: {},
    };
  },
  computed: {
    numOrders: {
      get() {
        return this.$store.state.qualification.num_orders;
      },
      set(value) {
        this.$store.commit("changeQualificationValues", {
          key: "num_orders",
          value: value,
        });
      },
    },
    numSites: {
      get() {
        return this.$store.state.qualification.num_sites;
      },
      set(value) {
        this.$store.commit("changeQualificationValues", {
          key: "num_sites",
          value: value,
        });
      },
    },
    annualRevenue: {
      get() {
        return this.$store.state.qualification.annual_rev;
      },
      set(value) {
        this.$store.commit("changeQualificationValues", {
          key: "annual_rev",
          value: value,
        });
      },
    },
  },
  mounted() {
    this.colors = getColors;
  },
  methods: {
    getSvg: getSvg,

    async handleSubmit() {
      console.log("Inputs: ", [...document.getElementsByTagName("input")]);
      try {
        this.$store.commit("setLoading");

        const leads = await fetch(
          `https://${process.env.VUE_APP_API_URL}/leads/${this.$store.state.business.lead_id}`,
          {
            method: "PATCH",
            referrerPolicy: "no-referrer",
            headers: new Headers({
              Authorization: `Bearer ${this.$store.state.access_token}`,
              Accept: "application/vnd.kangaroorewards.api.v1+json;",
              "Content-Type": "application/json",
            }),
            body: JSON.stringify({
              business: {
                estimated_num_orders:
                  this.$store.state.qualification.num_orders,
                estimated_annual_rev:
                  this.$store.state.qualification.annual_rev,
                num_site: this.$store.state.qualification.num_sites,
              },
            }),
          },
        );

        if (!leads.ok) {
          let errorResponse = await leads.json();

          let error = {
            message: errorResponse[Object.keys(errorResponse)[0]][0],
            url: errorResponse.url,
            code: errorResponse.status,
          };
          throw error;
        }

        this.$router.push({ name: "Customization steps" });

        this.$store.commit("incrementProgress", {
          progress: 10,
        });
        this.$store.commit("resetLoading");
      } catch (error) {
        this.$store.commit("resetLoading");
        this.$store.commit("setDataError", {
          error: { message: error.message },
        });
        console.log("Error: ", error);
      }
    },
  },
};
</script>

<style scoped>
.button_shadow {
  box-shadow: 0px 20px 50px #75bdd133;
}

.form_input_krssu {
  border: 2px solid #2eafd37e;
}

.valid_input {
  border: 2px solid #2eafd37e;
  background: #2eafd308 0% 0% no-repeat padding-box;
}

.form_input_krssu {
  margin-top: 12px;
  color: #2eafd3;
  font-size: 1.1875rem !important;
  font-weight: bold;
  padding-left: 21px;
  padding-right: 51px;
  padding-top: auto;
  padding-bottom: auto;
  width: 100%;
}

.form_container_krssu {
  display: flex;
  justify-content: space-around;
  align-content: space-around;
  align-items: center;
}

@media screen and (max-width: 1170px) {
  .form_container_krssu {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: space-around;
    justify-content: space-around;
  }
}

@media screen and (max-width: 1170px) {
  .form_element_krssu {
    margin-top: calc(2rem * calc(1 - 0));
    margin-bottom: calc(2rem * 0);
  }
}

.form_margin_krssu {
  margin-top: 95px;
}

@media screen and (max-width: 1170px) {
  .form_margin_krssu {
    margin-top: 0px;
  }
}

@media screen and (max-width: 1440px) {
  .form_input_krssu {
    width: 100% !important;
  }
}

input {
  color: #2eafd3;
  font-weight: bold;
  padding-top: auto;
  padding-bottom: auto;
}

.form_input_krssu::placeholder {
  color: #2eafd37e;
  font-size: 1.1875rem !important;
  font-weight: bold;
  padding-top: auto;
  padding-bottom: auto;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
