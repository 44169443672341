<template>
  <div>
    <div class="main-general-kr">
      <div class="main-title-kr ew_border-primary-kr">
        {{ this.$store.state.ew_translations.wteTitle }}
      </div>
      <div class="container-kr">
        <wte-rectangle
          style="margin-top: 12px"
          v-for="wte in wtesArray"
          :key="wte.title"
          :title="wte.title"
          :description="wte.shortDescription"
          :showArrow="true"
          :svgKey="wte.icon"
          @set-item="$emit('set-wte', wte)"
        ></wte-rectangle>
      </div>
    </div>
  </div>
</template>

<script>
import { getSvg } from "../utils/svgLibrary";
import WteRectangle from "../components/WteRectangle.vue";
export default {
  name: "WteTab",
  props: ["wtesArray"],
  emits: ["set-wte"],
  components: {
    WteRectangle,
  },
  data() {
    return {
      iconColor: "",
    };
  },
  methods: {
    getSvg: getSvg,
  },
  beforeMount() {
    this.iconColor = this.$store.state.ew_settings.iconPrimaryColor;
  },
};
</script>

<style scoped>
.main-general-kr {
  width: 100%;
  background-color: rgba(255, 255, 255, 0.15);
  overflow: hidden;
  border-radius: 24px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 6px;
}

.main-title-kr {
  display: flex;
  justify-content: center;
  font-weight: 700;
  padding-bottom: 16px;
  border-bottom-width: 1px;
}

.container-kr {
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
</style>