<template>
  <div class="card-kr ew_border-primary-kr">
    <!-- points, title and description starts -->
    <div class="content-kr">
      <div class="body-kr">
        <!-- title starts -->
        <div class="title-kr">
          {{
            getTitle(transaction.type)
              ? getTitle(transaction.type)
              : transaction.description
          }}
        </div>
        <!-- title ends -->
        <div v-if="getTitle(transaction.type)" class="description-kr">
          {{ transaction.description }}
        </div>
        <!-- code starts -->
        <div v-if="transaction.couponStatus === 1" style="display: flex">
          <div class="coupon-code-kr">
            {{ transaction.couponCode }}
          </div>
          <input
            id="transaction-code"
            type="hidden"
            :value="transaction.couponCode"
          />
          <div
            style=""
            class="copy-icon-kr"
            @click="copyText"
            v-html="
              getSvg(
                this.$store.state.ew_settings.iconPrimaryColor,
                14,
                14,
                9999,
              )
            "
          ></div>
        </div>
        <!-- code ends -->
      </div>
      <div
        v-if="
          transaction.earnedPts > 0 ||
          transaction.redeemedPts > 0 ||
          transaction.redeemedCreds > 0 ||
          transaction.earnedCreds > 0
        "
        class="value-kr"
      >
        {{ getTransactionValue(valueArr).symbol
        }}{{ getTransactionValue(valueArr).value
        }}{{ getTransactionValue(valueArr).unit }}
      </div>
    </div>
    <!-- points, title and description ends -->
    <!-- date + cancel starts -->
    <div class="date-btn-container-kr">
      <!-- date starts  -->
      <div class="date-kr" style="">
        <span
          class=""
          style="font-weight: 700"
          v-html="getDatePref(transaction.type)"
        ></span
        >{{ moment(transaction.date).lang("en").format("L") }}
      </div>
      <!-- date ends  -->
      <!-- cancel starts -->
      <div>
        <div
          class="cancel-btn-kr"
          v-if="transaction.couponStatus === 1"
          @click="reclaimCode(transaction.id)"
        >
          {{ this.$store.state.ew_translations.cancel }}
        </div>
      </div>
      <!-- cancel ends -->
    </div>
    <!-- date + cancel ends -->
  </div>
</template>

<script>
import moment from "moment";
import { getSvg, getTitle, getDatePref } from "../utils/historySettings";
import { addComma } from "../utils/formatNumber";
export default {
  name: "TransactionRectangle",
  props: ["transaction"],
  data() {
    return {
      textCopied: false,
    };
  },
  computed: {
    valueArr: {
      get() {
        return [
          {
            value: this.transaction.earnedPts,
            symbol: "+",
            unit: this.$store.state.ew_translations.ptsLabel,
          },
          {
            value: this.transaction.redeemedPts,
            symbol: "-",
            unit: this.$store.state.ew_translations.ptsLabel,
          },
          {
            value: this.transaction.redeemedCreds,
            symbol: "-",
            unit: this.$store.state.ew_translations.currencySymbol,
          },
          {
            value: this.transaction.earnedCreds,
            symbol: "+",
            unit: this.$store.state.ew_translations.currencySymbol,
          },
        ];
      },
    },
  },

  methods: {
    getSvg: getSvg,
    getTitle: getTitle,
    getDatePref: getDatePref,
    moment: moment,
    addComma: addComma,

    getTransactionValue(arr) {
      let val = 0;
      let symbol = "";
      let unit = "";
      let tempArr = [];

      arr.forEach((element) => {
        if (element.value > 0) {
          val = element.value;
          symbol = element.symbol;
          unit = element.unit;
          tempArr.push({ value: val, symbol: symbol, unit: unit });
        }
      });

      let index = undefined;
      index = tempArr.findIndex(
        (val) => val.unit == this.$store.state.ew_translations.ptsLabel,
      );

      if (index > -1) {
        return tempArr[index];
      } else {
        return tempArr[0];
      }
    },

    copyText() {
      const textToCopy = document.querySelector("#transaction-code");
      textToCopy.setAttribute("type", "text");
      textToCopy.select();

      try {
        var successful = document.execCommand("copy");
        this.textCopied = successful;
        setInterval(() => {
          this.textCopied = false;
        }, 1000);
        this.$store.commit("showNotification", {
          type: "success",
          title: "Copy done",
          message: "The link was copied succesfully",
        });
        setTimeout(() => {
          this.$store.commit("closeNotification");
        }, 5000);
      } catch (error) {
        this.$store.commit("showNotification", {
          type: "error",
          title: "Copy failed",
          message: "The link wasn't copied succesfully",
        });
        setTimeout(() => {
          this.$store.commit("closeNotification");
        }, 5000);
      }
      textToCopy.setAttribute("type", "hidden");
      window.getSelection().removeAllRanges();
    },

    async reclaimCode(id) {
      this.$store.commit("changeTransaction", {
        id: id,
        key: "couponStatus",
        value: 0,
      });
    },
  },
};
</script>

<style scoped>
.card-kr {
  background-color: rgba(255, 255, 255, 0.15);
  border-radius: var(--border_radius);
  padding: 16px;
  cursor: default;
  border-bottom-width: var(--border_bottom_width);
  box-shadow: var(--box_shadow);
}

.content-kr {
  display: flex;
  justify-content: space-between;
}

.body-kr {
  display: grid;
  place-items: start;
}

.title-kr {
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  text-align: left;
  text-transform: capitalize;
}

.description-kr {
  font-size: 12px;
  line-height: 16px;
  font-weight: 300;
  text-align: left;
}

.coupon-code-kr {
  font-size: 12px;
  line-height: 16px;
  font-weight: 300;
}

.copy-icon-kr {
  cursor: pointer;
  margin-left: 4px;
}

.value-kr {
  font-size: 14px;
  line-height: 20px;
}

.date-btn-container-kr {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
}

.date-kr {
  font-size: 12px;
  line-height: 16px;
  text-align: left;
}

.cancel-btn-kr {
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  text-decoration: underline;
  cursor: pointer;
  text-align: right;
}
</style>