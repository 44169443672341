export const getSvg = (color, width, height, key) => {
  switch (key) {
    case "line":
      return ` <svg
      xmlns="http://www.w3.org/2000/svg"s
      width="297"
      height="8"
      viewBox="0 0 297 8"
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          y1="0.5"
          x2="1"
          y2="0.5"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stop-color="#fff" stop-opacity="0" />
          <stop offset="0.517" stop-color="#fff" stop-opacity="0.349" />
          <stop offset="1" stop-color="#fff" stop-opacity="0" />
        </linearGradient>
      </defs>
      <g id="divider" transform="translate(-1101 -465.5)">
        <rect
          id="divider_shadow"
          data-name="divider shadow"
          width="291"
          height="2"
          transform="translate(1104 468.5)"
          fill="url(#linear-gradient)"
        />
        <rect
          id="divider-2"
          data-name="divider"
          width="291"
          height="2"
          transform="translate(1104 468)"
          fill="url(#linear-gradient)"
        />
      </g>
    </svg>
      `;
    case "facebook":
      return `  <svg
    xmlns="http://www.w3.org/2000/svg"
    width=${width}
    height=${height}
    viewBox="0 0 13.629 13.575"
  >
    <path
      id="Path_16"
      data-name="Path 16"
      d="M8.815,2.04A6.83,6.83,0,0,0,7.751,15.615v-4.77H6.021V8.868H7.751V7.362a2.4,2.4,0,0,1,2.576-2.651,10.683,10.683,0,0,1,1.52.129V6.524h-.859A.985.985,0,0,0,9.878,7.587V8.868h1.894l-.307,1.976H9.878v4.77A6.83,6.83,0,0,0,8.815,2.04Z"
      transform="translate(-2 -2.04)"
      fill=${color}
      opacity="0.85"
    />
  </svg>
    `;
    case "twitter":
      return `  <svg
      xmlns="http://www.w3.org/2000/svg"
      width=${width}
      height=${height}
      viewBox="0 0 12.748 10.359"
    >
      <path
        id="Path_26"
        data-name="Path 26"
        d="M14.288,5.219a5.221,5.221,0,0,1-1.5.42,2.619,2.619,0,0,0,1.146-1.45,5.265,5.265,0,0,1-1.657.64A2.574,2.574,0,0,0,10.351,4a2.611,2.611,0,0,0-2.6,2.614,2.717,2.717,0,0,0,.067.6A7.431,7.431,0,0,1,2.43,4.481a2.6,2.6,0,0,0,.81,3.479,2.585,2.585,0,0,1-1.188-.3v.018a2.616,2.616,0,0,0,2.1,2.565,2.563,2.563,0,0,1-1.176.043A2.608,2.608,0,0,0,5.409,12.1,5.193,5.193,0,0,1,2.162,13.22a5.315,5.315,0,0,1-.622-.037A7.436,7.436,0,0,0,12.99,6.919c0-.116,0-.225-.006-.341A5.223,5.223,0,0,0,14.288,5.219Z"
        transform="translate(-1.54 -4)"
        fill=${color}
        opacity="0.85"
      />
    </svg>
      `;
    case "hand":
      return ` <svg 
      xmlns="http://www.w3.org/2000/svg" 
      width=${width}
      height=${height} 
      viewBox="0 0 16.214 15.408"
      >
      <path id="Path_18" data-name="Path 18" d="M15.634,13.556a1.506,1.506,0,0,1,1.117.462A1.463,1.463,0,0,1,17.2,15.1l-6.186,2.311L5.619,15.867V8.934h1.5l5.6,2.072a.864.864,0,0,1,.6.863.878.878,0,0,1-.262.632.938.938,0,0,1-.663.285H10.241l-1.348-.516-.254.724,1.6.562h5.393M12.552,2.948A2.625,2.625,0,0,1,14.632,2a2.338,2.338,0,0,1,1.772.77,2.7,2.7,0,0,1,.77,1.772,3.51,3.51,0,0,1-.77,1.9,13.374,13.374,0,0,1-1.518,1.841q-.747.74-2.334,2.2-1.6-1.456-2.357-2.2A12.672,12.672,0,0,1,8.677,6.437a3.41,3.41,0,0,1-.747-1.9A2.373,2.373,0,0,1,8.677,2.77a2.622,2.622,0,0,1,3.875.177M.984,8.934H4.078v8.474H.984Z" transform="translate(-0.984 -2)" 
      fill=${color}
      />
    </svg>
    `;
    case "previousArrow":
      return `  <svg
      xmlns="http://www.w3.org/2000/svg"
      width=${width}
      height=${height}
      fill="none"
      viewBox="0 0 24 24"
      stroke=${color}
    >
    <path
    stroke-linecap="round"
    stroke-linejoin="round"
    stroke-width="2"
    d="M15 19l-7-7 7-7"
  />
    </svg>`;
    case "nextArrow":
      return `  <svg
      xmlns="http://www.w3.org/2000/svg"
      width=${width}
      height=${height}
      fill="none"
      viewBox="0 0 24 24"
      stroke=${color}
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M9 5l7 7-7 7"
      />
    </svg>`;
    case "success":
      return `  <svg
      xmlns="http://www.w3.org/2000/svg"
      width=${width}
      height=${height}
      fill="none"
      viewBox="0 0 24 24"
      stroke=${color}
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>  
      `;
    case "warning":
      return `  <svg
      xmlns="http://www.w3.org/2000/svg"
      width=${width}
      height=${height}
      fill="none"
      viewBox="0 0 24 24"
      stroke=${color}
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
      />
    </svg>`;
    case "error":
      return `  <svg
      xmlns="http://www.w3.org/2000/svg"
      width=${width}
      height=${height}
      fill="none"
      viewBox="0 0 24 24"
      stroke=${color}
    >
    <path
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="3"
    d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
  />
    </svg>
      `;
    case "arrow":
      return `   <svg
      xmlns="http://www.w3.org/2000/svg"
      width=${width}
      height=${height}
      viewBox="0 0 13.536 13.536"
    >
      <path
        id="_next"
        data-name=" next"
        d="M0,7.37V0H7.773"
        transform="translate(12.122 6.626) rotate(135)"
        fill="none"
        stroke=${color}
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        opacity="0.75"
      />
    </svg>

        `;
    case "backArrow":
      return `  <svg
      xmlns="http://www.w3.org/2000/svg"
      width=${width}
      height=${height}
      fill="none"
      viewBox="0 0 24 24"
      stroke=${color}
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="3"
        d="M15 19l-7-7 7-7"
      />
    </svg>
        `;
    case "copy":
      return `  <svg
      xmlns="http://www.w3.org/2000/svg"
      width=${width}
      height=${height}
      viewBox="0 0 10.364 12"
    >
      <path
        id="Path_28"
        data-name="Path 28"
        d="M11.273,11.909h-6V4.273h6m0-1.091h-6A1.091,1.091,0,0,0,4.182,4.273v7.636A1.091,1.091,0,0,0,5.273,13h6a1.091,1.091,0,0,0,1.091-1.091V4.273a1.091,1.091,0,0,0-1.091-1.091M9.636,1H3.091A1.091,1.091,0,0,0,2,2.091V9.727H3.091V2.091H9.636Z"
        transform="translate(-2 -1)"
        fill=${color}
        opacity="0.8"
      />
    </svg>
        `;
    case "roundedClose":
      return `     <svg
      xmlns="http://www.w3.org/2000/svg"
      width=${width}
      height=${height}
      viewBox="0 0 20 20"
    >
    <path
    id="icon"
    d="M12,20a8,8,0,1,1,8-8,8.011,8.011,0,0,1-8,8M12,2A10,10,0,1,0,22,12,9.991,9.991,0,0,0,12,2m2.59,6L12,10.59,9.41,8,8,9.41,10.59,12,8,14.59,9.41,16,12,13.41,14.59,16,16,14.59,13.41,12,16,9.41Z"
    transform="translate(-2 -2)"
    fill=${color}
    opacity="0.9"
  />
    </svg>
        `;
    case "settings":
      return ` <svg
      xmlns="http://www.w3.org/2000/svg"
      width=${width}
      height=${height}
      viewBox="0 0 11.06 9.954"
    >
      <path
        id="Path_5"
        data-name="Path 5"
        d="M5.765,7.607V2H4.659V7.607a2.2,2.2,0,0,0,0,4.269V13.06H5.765V11.877a2.2,2.2,0,0,0,0-4.269M5.212,8.636A1.106,1.106,0,1,1,4.106,9.742,1.109,1.109,0,0,1,5.212,8.636M11.3,2H10.189V3.183a2.2,2.2,0,0,0,0,4.269V13.06H11.3V7.453a2.2,2.2,0,0,0,0-4.269V2m-.553,2.212A1.106,1.106,0,1,1,9.636,5.318,1.109,1.109,0,0,1,10.742,4.212Z"
        transform="translate(13.06 -3) rotate(90)"
        fill=${color}
        opacity="0.85"
      />
    </svg>

        `;
    case "dot":
      return `      <svg
      xmlns="http://www.w3.org/2000/svg"
      width=${width}
      height=${height}
      viewBox="0 0 6 6"
    >
      <circle
        id="Ellipse_8"
        data-name="Ellipse 8"
        cx="3"
        cy="3"
        r="3"
        fill=${color}
        opacity="0.95"
      />
    </svg>
        `;
    case "gift":
      return ` <svg
      xmlns="http://www.w3.org/2000/svg"
      width=${width}
      height=${height}
      viewBox="0 0 13.825 13.827"
    >
      <path
        id="complete"
        d="M15.168,7.531l-1.8-1.047A1.976,1.976,0,0,0,11.27,3.179l-.573.244-.072-.632A1.923,1.923,0,0,0,9.7,1.276,1.98,1.98,0,0,0,6.714,2.646L4.9,1.605a1.308,1.308,0,0,0-1.8.481L2.448,3.225a.659.659,0,0,0,.244.9L7.82,7.09l.988-1.712,1.139.658L8.959,7.748l5.129,2.963a.676.676,0,0,0,.9-.244l.658-1.139a1.313,1.313,0,0,0-.481-1.8m-6.34-3.9a.652.652,0,0,1-.744-.955.662.662,0,0,1,.9-.244.652.652,0,0,1-.158,1.2m3.417,1.975a.65.65,0,0,1-.737-.955.654.654,0,1,1,.737.955m2.179,5.846a1.264,1.264,0,0,0,.744-.25v2.3a1.312,1.312,0,0,1-1.317,1.317H3.317A1.316,1.316,0,0,1,2,13.509V7.583H7.366l.56.329v5.6H9.242V8.67l4.523,2.607A1.25,1.25,0,0,0,14.424,11.455Z"
        transform="translate(-2 -0.999)"
        fill=${color}
      />
    </svg>`;
    case "closedGift":
      return ` <svg
      xmlns="http://www.w3.org/2000/svg"
      width=${width}
      height=${height}
      viewBox="0 0 13.825 13.827"
    >
    <path
    id="incomplete"
    d="M6.034,1.93a1.879,1.879,0,0,0-1.8,2.542H2.249A1.249,1.249,0,0,0,1,5.721V6.97a.625.625,0,0,0,.625.625H7.246V5.721H8.495V7.595h5.621a.625.625,0,0,0,.625-.625V5.721a1.249,1.249,0,0,0-1.249-1.249h-1.98A1.873,1.873,0,0,0,8.226,2.748l-.356.475-.356-.487a1.806,1.806,0,0,0-1.48-.806M6,3.223a.626.626,0,1,1-.625.625A.626.626,0,0,1,6,3.223m3.747,0a.626.626,0,1,1-.625.625.626.626,0,0,1,.625-.625m-8.119,5v5a1.249,1.249,0,0,0,1.249,1.249h9.993a1.249,1.249,0,0,0,1.249-1.249v-5H8.495v5H7.246v-5Z"
    transform="translate(-1 -1.93)"
    fill=${color}
  />  
    </svg>`;
    case "REWARD_PURCHASE":
      return `   <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    width=${width}
    height=${height}
    viewBox="0 0 24 24"
    fill=${color}
  >
    <path
      d="M12,13A5,5 0 0,1 7,8H9A3,3 0 0,0 12,11A3,3 0 0,0 15,8H17A5,5 0 0,1 12,13M12,3A3,3 0 0,1 15,6H9A3,3 0 0,1 12,3M19,6H17A5,5 0 0,0 12,1A5,5 0 0,0 7,6H5C3.89,6 3,6.89 3,8V20A2,2 0 0,0 5,22H19A2,2 0 0,0 21,20V8C21,6.89 20.1,6 19,6Z"
    />
  </svg>`;
    case "REWARD_REFERRAL":
      return `   <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width=${width}
      height=${height}
      viewBox="0 0 24 24"
      fill=${color}
    >
      <path
        d="M16 17V19H2V17S2 13 9 13 16 17 16 17M12.5 7.5A3.5 3.5 0 1 0 9 11A3.5 3.5 0 0 0 12.5 7.5M15.94 13A5.32 5.32 0 0 1 18 17V19H22V17S22 13.37 15.94 13M15 4A3.39 3.39 0 0 0 13.07 4.59A5 5 0 0 1 13.07 10.41A3.39 3.39 0 0 0 15 11A3.5 3.5 0 0 0 15 4Z"
      />
    </svg>`;
    case "REWARD_PRODUCT_REVIEWS":
      return `  <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width=${width}
      height=${height}
      fill=${color}
      viewBox="0 0 24 24"
    >
      <path
        d="M18,14H10.5L12.5,12H18M6,14V11.5L12.88,4.64C13.07,4.45 13.39,4.45 13.59,4.64L15.35,6.41C15.55,6.61 15.55,6.92 15.35,7.12L8.47,14M20,2H4A2,2 0 0,0 2,4V22L6,18H20A2,2 0 0,0 22,16V4C22,2.89 21.1,2 20,2Z"
      />
    </svg>
      `;
    case "REWARD_SHARE_SOCIAL_MEDIA":
      return `  <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width=${width}
      height=${height}
      fill=${color}
      viewBox="0 0 24 24"
    >
      <path
        d="M12 2.04C6.5 2.04 2 6.53 2 12.06C2 17.06 5.66 21.21 10.44 21.96V14.96H7.9V12.06H10.44V9.85C10.44 7.34 11.93 5.96 14.22 5.96C15.31 5.96 16.45 6.15 16.45 6.15V8.62H15.19C13.95 8.62 13.56 9.39 13.56 10.18V12.06H16.34L15.89 14.96H13.56V21.96A10 10 0 0 0 22 12.06C22 6.53 17.5 2.04 12 2.04Z"
      />
    </svg>
      `;
    default:
      return ``;
  }
};
