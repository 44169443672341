<template>
  <div
    :style="cssVars"
    class="widget_button flex justify-between mx-auto text-white"
  >
    <div style="width: 65%" class="flex">
      <div class="title flex justify-center items-center my-auto">
        <div class="mr-2" v-html="getSvg('#fff', 20, 20, 'gift')"></div>
        <div class="text-sm">
          {{ title }}
        </div>
      </div>
    </div>
    <div class="flex h-full" style="width: 35%">
      <div class="points flex h-full justify-center items-center">
        <div class="m-1 text-sm">2,000 {{ points }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getSvg } from "../../utils/getSvg";
export default {
  name: "WidgetButtonMock",

  computed: {
    title() {
      return this.$store.state.widgetTitle;
    },
    points() {
      return this.$store.state.loyaltyPoints;
    },
    cssVars() {
      return {
        "--primary_color": `${this.$store.state.widgetPrimaryColor}`,
        "--secondary_color": `${this.$store.state.widgetSecondaryColor}`,
      };
    },
  },

  data() {
    return {
      sideMenuItems: [
        { id: 1, name: "Offers", active: false },
        { id: 2, name: "Earn Points", active: false },
        { id: 3, name: "Refer Friends", active: false },
        { id: 4, name: "Reward Catalog", active: true },
        { id: 5, name: "Redeem Points", active: false },
        { id: 6, name: "Redeem In-Store Credit", active: false },
        { id: 7, name: "History", active: false },
        { id: 8, name: "Settings", active: false },
      ],
    };
  },

  methods: {
    getSvg: getSvg,
  },
};
</script>

<style scoped>
.widget_button {
  width: 70%;
  height: 55px;
  background: var(--primary_color) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 50px #19637740;
  border-radius: 100px;
  opacity: 1;
}

@media (max-width: 1170px) {
  .widget_button {
    display: none;
  }
}

.points {
  width: 100%;
  background: var(--secondary_color) 0% 0% no-repeat padding-box;
  border-radius: 0px 100px 100px 0px;
  text-align: center;
}

.title {
  width: 100%;
  text-align: center;
}
</style>
