<template>
  <div class="navbar_krssu">
    <div class="navbar_inner_krssu w-full flex items-center justify-center">
      <div class="mr-auto ml-8 w-44 h-8 bg-cover bg-center">
        <img
          :class="
            !['/', '/packages-subscription'].includes(currentRoutePath)
              ? 'full_logo_krssu1'
              : 'full_logo_krssu'
          "
          src="@/assets/logo.png"
        />
        <img
          :class="
            !['/', '/packages-subscription'].includes(currentRoutePath)
              ? 'small_logo_krssu1'
              : 'small_logo_krssu'
          "
          src="@/assets/logo-1.png"
        />
      </div>

      <div
        v-if="!['/', '/packages-subscription'].includes(currentRoutePath)"
        class="w-full max-w-xs mr-auto"
      >
        <progress
          class="progress_bar_krssu"
          id="file"
          max="100"
          :value="progress"
        ></progress>
      </div>
      <!-- <div>
          <div class="select_wrapper_krssu ml-auto mr-16">
         <select
            class="lang_select_krssu"
            v-model="selectedLanguage"
            @change="handleChange($event)"
          >
            <option value="en">{{ $t("languages.english") }}</option>
            <option value="fr">{{ $t("languages.french") }}</option>
          </select> 
        </div>
      </div>-->
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  props: ["text"],
  data() {
    const lang = localStorage.getItem("lang") || "en";
    return {
      selectedLanguage: lang,
    };
  },
  computed: {
    currentRoutePath() {
      return this.$route.path;
    },
    progress() {
      return this.$store.state.progress;
    },
  },
  methods: {
    handleChange(ev) {
      localStorage.setItem("lang", ev.target.value);
      window.location.reload();
    },
  },
};
</script>

<style scoped>
.full_logo_krssu {
  display: block;
}
.full_logo_krssu1 {
  display: block;
}

@media (max-width: 470px) {
  .full_logo_krssu {
    display: none;
  }

  .small_logo_krssu {
    display: block;
  }
}

@media (max-width: 830px) {
  .full_logo_krssu1 {
    display: none;
  }

  .small_logo_krssu1 {
    display: block;
  }
}

@media (min-width: 470px) {
  .small_logo_krssu {
    display: none;
  }
}

@media (min-width: 830px) {
  .small_logo_krssu1 {
    display: none;
  }
}

.navbar_krssu {
  color: rgba(17, 24, 39, 1);
  background-color: rgba(255, 255, 255, 1);
  height: 70px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

@media (max-width: 360px) {
  .progress_bar_krssu {
    display: none;
  }
}
.progress_bar_krssu {
  width: 100%;
  height: 10px;
  -webkit-appearance: none;
  appearance: none;
  box-shadow: 0px 20px 50px #75bdd133;
  border: 1px solid rgba(46, 175, 211, 0.1);
  border-radius: 100px;
}

.progress_bar_krssu::-webkit-progress-bar {
  background-color: rgba(46, 175, 211, 0.1);
}

.progress_bar_krssu::-webkit-progress-value {
  background: #2eafd3;
  box-shadow: 0px 20px 50px #75bdd133;
  border: 1px solid #2eafd3;
  border-radius: 100px;
  opacity: 1;
}

.lang_select_krssu {
  -webkit-appearance: none;
  appearance: none;
  text-align: center;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0px;
  color: #929292;
  opacity: 1;
}

.select_wrapper_krssu {
  position: relative;
}

.select_wrapper_krssu::after {
  content: url(../assets/icons/down-arrow.svg);
  font-size: 1rem;
  top: 4px;
  right: -25px;
  position: absolute;
}
</style>
