<template>
  <div class="loyalty_page_krssu">
    <div style="height: 45px">
      <div class="flex">
        <div>Home</div>
        <div>About</div>
        <div>Contact</div>
        <div>Blog</div>
        <div>Login</div>
        <div class="vl_krssu"></div>
        <div>Search</div>
        <div class="vl_krssu"></div>
        <div>Basket</div>
      </div>
    </div>
    <slot name="element"></slot>
  </div>
</template>

<script>
export default {
  name: "LoyaltyPageMock",
  props: { title: { type: String } },
};
</script>

<style scoped>
.loyalty_page_krssu {
  width: 476px;
  height: 857px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 50px #19637740;
  border-radius: 20px;
  opacity: 1;
}

.vl_krssu {
  height: 23px;
  border-left: 1px solid var(--primary_color);
  opacity: 1;
  margin-right: 13.5px;
  margin-left: 13.5px;
}
</style>
