<template>
  <div
    :style="cssVars"
    class="widget_krssu justify-center overflow-hidden flex w-full mt-9"
  >
    <!-- <img src="../../assets/images/new-widget.png" /> -->
    <NewWidget />
    <!-- <div style="width: 37%">
      <div class="title_krssu flex items-center text-sm text-white">
        <div class="ml-3">{{ title }}</div>
      </div>
      <div style="" class="greetings_card_krssu text-right text-white pt-2">
        <div class="mr-2 py-1 text-sm">Hi Emma,<br />1,292 {{ points }}</div>
      </div>
      <div>
        <div
          v-for="item in sideMenuItems"
          :key="item.id"
          :class="[
            activeTab === item.id
              ? 'active_menu_item_krssu text-white cursor-pointer'
              : item.id === 5 || item.id === 7 || item.id === 8
              ? 'opacity-40 cursor-not-allowed'
              : 'inactive_menu_item_krssu cursor-pointer',
            'menu_item_krssu flex items-center text-xs p-1',
          ]"
          disabled
          @click="changeActiveTab(item.id)"
        >
          <span class="ml-2 text-xs"> {{ item.name }}</span>
        </div>
      </div>
      <div class="fill_rest_krssu"></div>
    </div>
    <div
      style="
        width: 63%;
        height: 49px;
        border-bottom: 2px solid #969696;
        border-color: #969696;
        color: #969696;
      "
    >
      <div
        class="float-right mr-3 mt-4"
        v-html="getSvg(this.$store.state.widgetSecondaryColor, 20, 20, 'X')"
      ></div>
      <div class="mt-4 ml-4 text-sm">
        {{
          activeTab === 1
            ? "Offers"
            : activeTab === 4
            ? "Reward Catalog"
            : activeTab === 2
            ? "Earn Points"
            : activeTab === 3
            ? "Refer Friends"
            : ""
        }}
      </div>
      <div class="p-2 mt-1 w-full h-full">
        <div
          v-if="activeTab === 1 || activeTab === 4"
          class="grid grid-cols-2 gap-y-4 gap-x-2 w-full p-2"
        >
          <WidgetCard
            v-for="item in catalog"
            :key="item.id"
            :description="item.title"
            :value="item.value"
            :unit="item.unit"
            :imgUrl="item.imgUrl"
            :type="item.type"
          />
        </div>
        <div v-if="activeTab == 2">
          <div
            class="
              rounded-lg
              w-full
              h-9
              bg-white
              mt-2
              mb-4
              shadow-lg
              overflow-hidden
            "
          >
            <div>
              <div class="flex justify-start bg-gray-200 w-full items-center">
                <div class="w-3 h-3 ml-2">
                  <img src="../../assets/icons/discount-bag.png" />
                </div>
                <div class="text-xs text-black pl-2 font-bold">
                  Make a purchase
                </div>
              </div>
              <div class="bg-white text-xs text-black ml-3">
                {{ this.$store.state.rewardSettings.points }} loyalty points per
                ${{ this.$store.state.rewardSettings.amount }}
              </div>
            </div>
          </div>
          <div
            class="
              rounded-lg
              w-full
              h-9
              bg-white
              my-2
              shadow-lg
              overflow-hidden
            "
          >
            <div>
              <div class="flex justify-start bg-gray-200 w-full items-center">
                <div class="w-3 h-3 ml-2">
                  <img src="../../assets/icons/refer_friend.png" />
                </div>
                <div class="text-xs text-black pl-2 font-bold">
                  Refer a friend
                </div>
              </div>
              <div class="bg-white text-xs text-black ml-3">
                10 loyalty points
              </div>
            </div>
          </div>
        </div>
        <div class="h-full p-4" v-if="activeTab == 3">
          <div
            class="flex mt-4 flex-col justify-center align-middle items-center"
          >
            <div class="w-9 h-9 mb-2">
              <img src="../../assets/icons/refer_friend.png" />
            </div>
            <div class="text-xs text-center text-black mb-2">
              Refer a friend and you will earn 10 loyalty points while your
              friend earns 20 loyalty points if she/he purchases more than $0.00
              at the store
            </div>
            <div class="bg-gray-200 p-2 text-xs text-center mb-2">
              https://yourlink/here
            </div>
            <div
              class="button_krssu rounded-lg p-2 text-xs text-center text-white"
            >
              Copy link
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import { getSvg } from "../../utils/getSvg";
import NewWidget from "@/components/NewWidget/NewWidget.vue";
// import WidgetCard from "@/components/Widget/WidgetCard.vue";
export default {
  name: "WidgetMock",
  components: {
    // WidgetCard,
    NewWidget,
  },
  data() {
    return {
      sideMenuItems: [
        { id: 1, name: "Offers", active: false },
        { id: 4, name: "Reward Catalog", active: true },
        { id: 2, name: "Earn Points", active: false },
        { id: 3, name: "Refer Friends", active: false },
        { id: 5, name: "Redeem Points", active: false },
        { id: 7, name: "History", active: false },
        { id: 8, name: "Settings", active: false },
      ],
      catalog: this.$store.state.offerSettings.filter(
        (offer) => offer.reward && offer.active,
      ),
      redemptionCatalog: [
        { id: 1, imgUrl: "", description: "5% Discount!" },
        { id: 2, imgUrl: "", description: "Free Shipping!" },
        { id: 3, imgUrl: "", description: "10% Discount!" },
        { id: 4, imgUrl: "", description: "20$ Discount!" },
        { id: 5, imgUrl: "", description: "Free Item!" },
        { id: 6, imgUrl: "", description: "20% Discount!" },
      ],
      activeTab: 4,
    };
  },
  computed: {
    title() {
      return this.$store.state.widgetTitle;
    },
    points() {
      return this.$store.state.loyaltyPoints;
    },
    cssVars() {
      return {
        "--primary_color": `${this.$store.state.widgetPrimaryColor}`,
        "--secondary_color": `${this.$store.state.widgetSecondaryColor}`,
      };
    },
  },
  methods: {
    getSvg: getSvg,

    changeActiveTab(id) {
      if (id === 1 || id === 4) {
        this.catalog =
          id === 4
            ? this.$store.state.offerSettings.filter(
                (offer) => offer.reward && offer.active,
              )
            : this.$store.state.offerSettings.filter(
                (offer) => offer.offer && offer.active,
              );

        this.activeTab = id;
      } else if (id === 2 || id === 3) {
        this.activeTab = id;
      } else {
        return;
      }
    },
  },
};
</script>

<style scoped>
.widget_krssu {
  overflow: hidden;
  height: 470px;

  border-radius: 20px;
  opacity: 1;
}

.title_krssu {
  width: 100%;
  height: 49px;
  background-color: var(--secondary_color);
}

.greetings_card_krssu {
  width: 100%;
  height: 65px;
  background-color: var(--primary_color);
}

.menu_item_krssu {
  border-bottom: 0.3px solid #9696968e;
}

.button_krssu {
  background-color: var(--primary_color);
}

.active_menu_item_krssu {
  background-color: var(--primary_color);
  color: #fff !important;
}
.inactive_menu_item {
  color: #a8a6a6;
}
.fill_rest_krssu {
  height: 100%;
  width: 100%;
  background-color: var(--primary_color);
}
</style>
