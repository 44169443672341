<template>
  <div class="modal_krssu" v-if="isActive" @click="close">
    <div class="modal_body_krssu">
      <div class="modal_content_krssu" style="z-index: 1;" ref="content">
        <slot />
      </div>

      <div
        class="flex justify-end align underline cursor-pointer hover:text-gray-900 "
        style="font-size: 17px; line-height: 8px; margin-top: 13px; margin-right: 9.5px; margin-bottom: 27px; margin-top: 27px"
      >
        <button @click.stop="close">{{ closeMsg }}</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PopupModal",
  props: ["closeMsg"],
  data() {
    return {
      isActive: true,
    };
  },
  methods: {
    open() {
      this.$emit("open");
      this.isActive = true;
    },

    close(e) {
      if (this.$refs.content.contains(e.target)) return;
      this.$emit("close");
      this.$store.commit("resetDataError");
    },
    closeModal() {
      this.$store.commit("resetDataError");
    },
    redeemReward() {
      this.$emit("redeem-reward");
    },
  },
};
</script>

<style scoped>
.modal_krssu {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.75);
  z-index: 1000;
}

.modal_body_krssu {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  width: 25.625rem;
  background: #fff;
  border-radius: 20px;
  padding: 20px 20px 0px 20px;
  margin: auto;
  height: auto;
}

.modal_content_krssu {
  flex: 1 0 90%;
}

.lp_modal__footer {
  margin-top: 25px;
  margin-bottom: 25px;
  display: flex;
  justify-content: flex-end;
}
</style>
