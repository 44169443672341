<template>
  <div class="card-kr ew_border-primary-kr">
    <!-- title starts -->
    <div class="title-kr">
      {{ title }}
    </div>
    <!-- title ends -->
    <!-- description starts -->
    <div class="description-kr">
      {{ description }}
    </div>
    <!-- description ends -->
    <!-- link section starts -->
    <div class="link-container-kr">
      <div class="link-kr">
        {{ link }}
      </div>
      <input id="referral-link" type="hidden" :value="link" />
      <div
        class="icon-kr ew_border-primary-kr"
        style=""
        @click="copyText"
        v-html="
          getSvg(
            this.$store.state.ew_settings.iconPrimaryColor,
            12.08,
            13.98,
            'copy',
          )
        "
      ></div>
      <div
        class="icon-kr ew_border-primary-kr"
        v-html="
          getSvg(
            this.$store.state.ew_settings.iconPrimaryColor,
            14.56,
            14.5,
            'facebook',
          )
        "
      ></div>
    </div>
    <!-- link section ends -->
  </div>
</template>

<script>
import { getSvg } from "../utils/svgLibrary";
export default {
  name: "ReferralRectangle",
  props: ["title", "description", "link"],
  data() {
    return {
      textCopied: false,
    };
  },
  methods: {
    getSvg: getSvg,
    copyText() {
      const textToCopy = document.querySelector("#referral-link");
      textToCopy.setAttribute("type", "text");
      textToCopy.select();
      try {
        var successful = document.execCommand("copy");
        this.textCopied = successful;
        setInterval(() => {
          this.textCopied = false;
        }, 1000);
        this.$store.commit("showNotification", {
          type: "success",
          title: this.$store.state.ew_translations.copied,
        });
        setTimeout(() => {
          this.$store.commit("closeNotification");
        }, 5000);
      } catch (error) {
        this.$store.commit("showNotification", {
          type: "error",
          title: "Copy failed",
        });
        setTimeout(() => {
          this.$store.commit("closeNotification");
        }, 5000);
      }
      textToCopy.setAttribute("type", "hidden");
      window.getSelection().removeAllRanges();
    },
  },
};
</script>

<style scoped>
.card-kr {
  width: 100%;
  border-radius: var(--border_radius);
  border-top-right-radius: var(--border_top_right_radius) !important;
  background-color: rgba(255, 255, 255, 0.15);
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 12px;
  padding-bottom: 12px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  vertical-align: middle;
  border-bottom-width: var(--border_bottom_width);
  box-shadow: var(--box_shadow);
  padding-bottom: var(--large_padding);
}

.title-kr {
  font-weight: 450;
  font-size: 15px;
  line-height: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.description-kr {
  font-weight: 350;
  font-size: 11px;
  line-height: 13px;
  margin-top: 13px;
  overflow-wrap: break-word;
}

.link-container-kr {
  margin-top: 17px;
  display: grid;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  justify-content: flex-start;
  column-gap: 8px;
  width: 100%;
}

.link-kr {
  grid-column: span 4 / span 4;
  font-size: 10px;
  line-height: 12px;
  background-color: rgba(255, 255, 255, 1);
  color: rgba(107, 114, 128, 1);
  padding: 10px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 8px !important;
  box-shadow: 0px 3px 10px #00000029;
  border-style: solid;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.icon-kr {
  width: 100%;
  grid-column: span 1 / span 1;
  border-style: solid;
  border-width: 1px;
  border-radius: 8px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
</style>