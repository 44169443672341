import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
// import LogRocket from "logrocket";
// import createPlugin from "logrocket-vuex";

// const logrocketPlugin = createPlugin(LogRocket);

const getDefaultState = () => {
  return {
    redirectUrl: "",
    packageVersion: "0.2.2",
    access_token: null,
    business: null,
    user: null,
    platform_id: null,
    cid: null,
    application_key: null,
    completedSteps: [],
    progress: 0,
    rewardSettings: { points: 2, amount: 1 },
    referralSettings: { value1: 50, value2: 150, value3: 30 },
    offerSettings: [
      {
        id: 16,
        offer: false,
        reward: true,
        title: "Discount",
        showingTitle: "$10 Discount",
        description: "Enjoy $10 off your order.",
        expiryDate: "12-01-2023",
        requiredPts: 300,
        percentageLeft: 10,
        pointsLeft: 200,
        unit: "$",
        type: "cashback",
        imgUrl: "moneyBag",
        value: 5,
        points: 100,
        active: true,
      },
      {
        id: 18,
        offer: false,
        reward: true,
        title: "Discount",
        showingTitle: "5% Discount",
        expiryDate: "12-1-2024",
        description: "Redeem your points and get 5% off.",
        requiredPts: 300,
        percentageLeft: 10,
        pointsLeft: 200,
        unit: "%",
        type: "percentage",
        imgUrl: "discountBag",
        value: 10,
        points: 500,
        active: true,
      },
      {
        id: 18,
        offer: false,
        reward: true,
        title: "Free Shipping",
        showingTitle: "Free Shipping",
        description: "Redeem your points for free shipping, you earned it!",
        expiryDate: null,
        requiredPts: 300,
        percentageLeft: 10,
        pointsLeft: 200,
        unit: "",
        type: "free_shipping",
        imgUrl: "truck",
        value: null,
        points: 1000,
        active: true,
      },
      {
        id: 9,
        offer: false,
        reward: false,
        type: "birthday",
        value: 200,
        points: null,
        active: true,
      },
      {
        id: 13,
        offer: false,
        reward: false,
        type: "first_visit",
        value: 1000,
        points: null,
        active: true,
      },
      {
        id: 1,
        code: 1231,
        offer: true,
        reward: false,
        title: "The Points",
        showingTitle: "2x points",
        description: "Earn 2x the points on your order.",
        typeId: 9,
        pointsLeft: 0,
        unit: "x",
        type: "multiplier",
        imgUrl: "calendarHour",
        expiryDate: "01-01-2025",
        points: 2,
        value: 2,
        active: true,
      },
    ],
    selectedElements: [],
    widgetTitle: "Your rewards",
    loyaltyPoints: "pts",
    widgetPrimaryColor: "#1b687e",
    widgetSecondaryColor: "#2eafd3",
    creatingBusinessLoader: false,
    dataError: null,
    loading: false,
    selectedThemeWid: 0,
    qualification: {
      num_orders: 0,
      num_sites: 1,
      annual_rev: "$0-$1m",
    },
    ew_user: {
      firstName: "Ashley",
      points: 2000,
      amount: 398,
      tier: "Gold",
      email: "ashleysmith@email.com",
      dob: "01/01/1990",
    },
    ew_wteList: [
      {
        title: "Make a Purchase",
        description: "Every order earns you points.",
        shortDescription: "Every order earns you points.",
        icon: "REWARD_PURCHASE",
      },
      {
        title: "Refer a Friend ",
        description: "Refer your friends to earn bonus points or offers.",
        shortDescription: "Refer your friends to earn bonus points or offers.",
        icon: "REWARD_REFERRAL",
      },
      {
        title: "Product Review",
        description:
          "Tell us what you think of our products and earn bonus points.",
        shortDescription:
          "Tell us what you think of our products and earn bonus points.",
        icon: "REWARD_PRODUCT_REVIEWS",
      },
    ],
    ew_referralSettings: {
      active: true,
      title: "Refer and Earn",
      description:
        "Share your referral link with friends, if they sign up you could both earn $5 off your next order.",
      link: "www.referral.com/user29…",
    },
    ew_consentList: [
      {
        id: 1,
        description: "I would like to receive promotions by SMS.",
      },
      {
        id: 2,
        description: "I would like to receive promotions by Email.",
      },
    ],
    ew_transactionsList: [
      {
        id: 0,
        type: 1,
        description: "Reward",
        date: "01-01-2023",
        redeemedPts: 0,
        earnedPts: 100,
        redeemedCreds: 0,
        earnedCreds: 0,
        couponStatus: 1,
        couponCode: "WEDFES3",
      },
      {
        id: 1,
        type: 1,
        description: "Redeem",
        date: "01-01-2023",
        redeemedPts: 40,
        earnedPts: 0,
        redeemedCreds: 0,
        earnedCreds: 0,
        couponStatus: 4,
        couponCode: "WEDFES3",
      },
      {
        id: 2,
        type: 1,
        description: "Product Review",
        date: "01-01-2023",
        redeemedPts: 0,
        earnedPts: 0,
        redeemedCreds: 30,
        earnedCreds: 0,
        couponStatus: 1,
        couponCode: "AKLO45G",
      },
      {
        id: 3,
        type: 1,
        description: "Refer a friend",
        date: "01-01-2023",
        redeemedPts: 0,
        earnedPts: 0,
        redeemedCreds: 0,
        earnedCreds: 5,
        couponStatus: 3,
        couponCode: "WEDFES3",
      },
    ],
    ew_selectedConsents: [1],
    ew_settings: {
      primaryColor: "#2eafd3",
      txtPrimaryColor: "#fff",
      iconPrimaryColor: "#fff",
      progressBgPrimaryColor: "#fff",
    },
    ew_translations: {
      welcome: "Welcome",
      youHave: "You have",
      ptsLabel: "pts",
      nextReward: "Next Reward",
      save: "Save",
      copied: "Copied",
      wteTitle: "Ways to earn",
      wteDesc: "explore how we reward you",
      pwpTitle: "Redeem points",
      pwpDesc: "pay with points",
      pwcTitle: "Redeem in store credits",
      pwcDesc: "pay with credits",
      noOffer: "No offers",
      currencySymbol: "$",
      cancel: "Reclaim",
      confirmRedemption: "Confirm Redemption",
      personalizeReward: "Personalized Reward",
      redeemNow: "Redeem Now",
      left: "left",
      youRedeemed: "You redeemed",
      yourCouponCode: "Your coupon code",
      applyToCart: "Apply to cart",
      appliedToCheckout: "Applied to checkout",
      willEarnYou: "will earn you",
    },
    ew_pwpState: true,
    ew_redeemingPwp: false,
    ew_pwcState: true,
    ew_redeemingPwc: false,
    ew_redemptionType: "reward",
    ew_notification: {
      active: false,
      type: "",
      title: "",
    },
    ew_selectedRedemption: null,
    ew_selectedWte: {},
    ew_minorLoading: false,
    ew_pwpSettings: {
      max: 500,
      min: 100,
      userAmount: 11111,
      dataMaxRedeem: 400,
      subTot: 15,
      ratio: 50,
    },
    ew_pwcSettings: {
      max: 20,
      min: 2,
      subTot: 23,
      userAmount: 198,
      dataMaxRedeem: 100,
      ratio: 50,
    },
  };
};

export default createStore({
  plugins: [createPersistedState()],
  // state: getDefaultState(),
  state: getDefaultState(),
  mutations: {
    resetState(state) {
      Object.assign(state, getDefaultState());
    },
    setAccessToken(state, payload) {
      state.access_token = payload.token;
    },
    resetAccessToken(state) {
      state.access_token = null;
    },
    setBusiness(state, payload) {
      state.business = payload.business;
    },
    resetBusiness(state) {
      state.business = null;
    },
    changeBusiness(state, payload) {
      state.business[payload.key] = payload.value;
    },
    setUser(state, payload) {
      state.user = payload.user;
    },
    resetUser(state) {
      state.user = null;
    },
    changeUser(state, payload) {
      state.user[payload.key] = payload.value;
    },
    setRedirectUrl(state, payload) {
      state.redirectUrl = payload.n;
    },
    changeQualificationValues(state, payload) {
      state.qualification[payload.key] = payload.value;
    },
    changeRewardSettings(state, payload) {
      state.rewardSettings[payload.key] = payload.value;
    },
    resetRewardSettings(state) {
      state.rewardSettings = {
        points: 2,
        amount: 1,
      };
    },
    changeReferralSettings(state, payload) {
      state.referralSettings[payload.key] = payload.value;
    },
    resetReferralSettings(state) {
      state.referralSettings = {
        value1: 50,
        value2: 150,
        value3: 30,
      };
    },
    changeOfferSettings(state, payload) {
      state.offerSettings[payload.key][payload.subKey] = payload.value;
    },
    resetOfferSettings(state) {
      state.offerSettings = [
        {
          id: 16,
          offer: false,
          reward: true,
          title: "Discount",
          showingTitle: "$10 Discount",
          expiryDate: "12-1-2023",
          requiredPts: 300,
          percentageLeft: 10,
          pointsLeft: 200,
          unit: "$",
          type: "cashback",
          imgUrl: "moneyBag",
          value: 5,
          points: 100,
          active: true,
        },
        {
          id: 18,
          offer: false,
          reward: true,
          title: "Discount",
          showingTitle: "5% Discount",
          expiryDate: "12-1-2024",
          requiredPts: 300,
          percentageLeft: 10,
          pointsLeft: 200,
          unit: "%",
          type: "percentage",
          imgUrl: "discountBag",
          value: 10,
          points: 500,
          active: true,
        },
        {
          id: 18,
          offer: false,
          reward: true,
          title: "Free Shipping",
          showingTitle: "Free Shipping",
          description: "Get a free shipping on your next order",
          expiryDate: null,
          requiredPts: 300,
          percentageLeft: 10,
          pointsLeft: 200,
          unit: "",
          type: "free_shipping",
          imgUrl: "truck",
          value: null,
          points: 1000,
          active: true,
        },
        {
          id: 9,
          offer: false,
          reward: false,
          type: "birthday",
          value: 200,
          points: null,
          active: true,
        },
        {
          id: 13,
          offer: false,
          reward: false,
          type: "first_visit",
          value: 1000,
          points: null,
          active: true,
        },
        {
          id: 1,
          code: 1231,
          offer: true,
          reward: false,
          title: "Points",
          showingTitle: "2x points",
          description: "This is a multiplier offer :)",
          typeId: 9,
          pointsLeft: 0,
          unit: "x",
          type: "multiplier",
          imgUrl: "calendarHour",
          expiryDate: "01-01-2025",
          points: 2,
          value: 2,
          active: true,
        },
      ];
    },

    setPlatformId(state, payload) {
      state.platform_id = payload.id;
    },
    resetPlatformId(state) {
      state.platform_id = null;
    },
    setCId(state, payload) {
      state.cid = payload.id;
    },
    resetCId(state) {
      state.cid = null;
    },
    setApplicationKey(state, payload) {
      state.application_key = payload.key;
    },
    resetApplicationKey(state) {
      state.application_key = null;
    },
    setCompletedSteps(state, payload) {
      state.completedSteps.push(payload.step);
    },
    resetCompletedSteps(state) {
      state.completedSteps = [];
    },
    removeCompletedStep(state, payload) {
      state.completedSteps = [
        ...state.completedSteps.filter(step => step !== payload.step),
      ];
    },
    incrementProgress(state, payload) {
      state.progress += payload.progress;
    },
    decrementProgress(state, payload) {
      state.progress -= payload.progress;
    },
    resetProgress(state) {
      state.progress = 0;
    },
    setSelectedElement(state, payload) {
      state.selectedElement.push(payload.element);
    },
    resetSelectedElement(state) {
      state.selectedElement = [];
    },
    removeSelectedElement(state, payload) {
      state.selectedElement = [
        ...state.selectedElement.filter(element => element !== payload.element),
      ];
    },
    setWidgetTitle(state, payload) {
      state.widgetTitle = payload.title;
    },
    resetWidgetTitle(state) {
      state.widgetTitle = "Your rewards";
    },
    setLoyaltyPoints(state, payload) {
      state.loyaltyPoints = payload.points;
    },
    resetLoyaltyPoints(state) {
      state.loyaltyPoints = "pts";
    },
    setWidgetPrimaryColor(state, payload) {
      state.widgetPrimaryColor = payload.color;
    },
    resetWidgetPrimaryColor(state) {
      state.widgetPrimaryColor = "#1b687e";
    },
    setWidgetSecondaryColor(state, payload) {
      state.widgetSecondaryColor = payload.color;
    },
    resetWidgetSecondaryColor(state) {
      state.widgetSecondaryColor = "#2eafd3";
    },
    setBusinessLoader(state) {
      state.creatingBusinessLoader = true;
    },
    resetBusinessLoader(state) {
      state.creatingBusinessLoader = false;
    },
    setDataError(state, payload) {
      state.dataError = payload.error;
    },
    resetDataError(state) {
      state.dataError = null;
    },
    setLoading(state) {
      state.loading = true;
    },
    resetLoading(state) {
      state.loading = false;
    },
    setSelectedThemeWid(state, n) {
      state.selectedThemeWid = n;
    },
    resetSelectedThemeWid(state) {
      state.selectedThemeWid = -1;
    },
    changeRedemptionType(state, n) {
      state.ew_redemptionType = n;
    },
    redeemPwp(state, n) {
      state.ew_redeemingPwp = n;
    },
    redeemPwc(state, n) {
      state.ew_redeemingPwc = n;
    },
    showNotification(state, payload) {
      state.ew_notification = {
        active: true,
        type: payload.type,
        title: payload.title,
      };
    },
    closeNotification(state) {
      state.ew_notification = {
        active: false,
        type: "",
        title: "",
      };
    },
    setRedemption(state, n) {
      console.log("NNN: ", n);
      state.ew_selectedRedemption = n;
      console.log("ssState: ", state.ew_selectedRedemption);
    },
    resetRedemption(state) {
      state.ew_selectedRedemption = {};
    },
    setWte(state, n) {
      state.ew_selectedWte = n;
    },
    resetWte(state) {
      state.ew_selectedWte = {};
    },
    changeSettings(state, n) {
      n.forEach(el => {
        state.ew_settings[el.key] = el.value;
      });
    },

    enableMinorLoading(state) {
      state.ew_minorLoading = true;
    },
    disableMinorLoading(state) {
      state.ew_minorLoading = false;
    },
    changeTransaction(state, n) {
      state.ew_transactionsList.forEach(transaction => {
        if (transaction.id == n.id) {
          console.log("Trans: ", transaction);
          transaction[n.key] = n.value;
        }
      });
    },
  },
  getters: {},
  actions: {},
  modules: {},
});
