<template>
  <div>
    <div class="main-div-kr">
      <div
        class="back-icon-kr"
        @click="$emit('go-back')"
        v-html="getSvg('#fff', 14, 14, 'backArrow')"
      ></div>
      <div class="title-kr">
        {{
          this.$store.state.ew_redeemingPwc
            ? `${this.$store.state.ew_translations.youHave}
         ${this.$store.state.ew_translations.currencySymbol} ${user.amount}`
            : `${this.$store.state.ew_translations.youHave} ${addComma(
                user.points,
              )} ${this.$store.state.ew_translations.ptsLabel}`
        }}
      </div>
    </div>
  </div>
</template>

<script>
import { getSvg } from "../../utils/svgLibrary";
import { addComma } from "../../utils/formatNumber";
export default {
  name: "MinimizedTop",
  props: ["user"],
  methods: {
    getSvg: getSvg,
    addComma: addComma,
  },
};
</script>

<style scoped>
.main-div-kr {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.back-icon-kr {
  margin-left: 4px;
  margin-right: 16px;
  cursor: pointer;
}

.title-kr {
  font-size: 16px;
  line-height: 16px;
  text-align: left;
}
</style>