<template>
  <div
    v-if="['/', '/packages-subscription'].includes(currentRoutePath)"
    class="wrapper_krssu"
  >
    <PopupModal
      v-if="this.$store.state.dataError"
      ref="errorModal"
      closeMsg="cancel"
    >
      <ErrorModal :description="this.$store.state.dataError.message" />
    </PopupModal>
    <div class="main_krssu min-h-screen w-full relative">
      <Header />
      <div
        class="
          w-full
          h-full
          flex flex-col
          items-center
          justify-center
          align-middle
        "
      >
        <div
          v-if="this.$store.state.loading"
          class="absolute top-1/2 left-1/2 z-50"
          style="height: auto; width: auto; transform: translate(-50%, -50%)"
        >
          <div class="loader"></div>
        </div>
        <slot v-else></slot>
      </div>
    </div>
  </div>
  <div class="wrapper_krssu" v-else>
    <PopupModal
      v-if="this.$store.state.dataError"
      ref="errorModal"
      closeMsg="cancel"
    >
      <ErrorModal :description="this.$store.state.dataError.message" />
    </PopupModal>
    <div class="main_krssu min-h-screen w-full relative">
      <Header />
      <div
        class="
          w-full
          h-full
          flex flex-col
          p-4
          items-center
          justify-center
          align-middle
        "
      >
        <div
          v-if="this.$store.state.loading"
          class="absolute top-1/2 left-1/2 z-50"
          style="height: auto; width: auto; transform: translate(-50%, -50%)"
        >
          <div class="loader"></div>
        </div>

        <slot v-else></slot>
      </div>
    </div>
    <div class="flex side_krssu1" style="width: 30%"></div>
    <div class="flex side_krssu" style="width: 30%">
      <div
        class="
          flex flex-col
          justify-center
          align-middle
          px-16
          bg-contain bg-sblue-100
        "
        :style="
          !['/widget-customization', '/loyalty-page-setup'].includes(
            currentRoutePath,
          )
            ? { backgroundImage: 'url(' + getImgUrl() + ')' }
            : ''
        "
        style="
          background-size: 100%
          background-repeat: no-repeat !important;
          width: 100%;
          border-radius: 20px;
          margin-left: 13px;
          margin-right: 13px;
          margin-top: 10px;
          margin-bottom: 10px;
        "
      >
        <div v-if="['/loyalty-page-setup'].includes(currentRoutePath)">
          <LoyaltyPageMock />
        </div>
        <div v-else-if="['/widget-customization'].includes(currentRoutePath)">
          <WidgetMock />
          <WidgetButtonMock class="mt-20" />
        </div>
        <!-- <div
          v-else
          :style="{ backgroundImage: 'url(' + getImgUrl() + ')' }"
          class="h-full w-full"
        >
          Hiii
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import LoyaltyPageMock from "@/components/LoyaltyPage/LoyaltyPageMock.vue";
import WidgetMock from "@/components/Widget/WidgetMock.vue";
import WidgetButtonMock from "@/components/Widget/WidgetButtonMock.vue";
import PopupModal from "@/components/PopupModal.vue";
import ErrorModal from "@/components/ErrorModal.vue";
export default {
  name: "Layout",
  components: {
    Header,
    LoyaltyPageMock,
    WidgetMock,
    WidgetButtonMock,
    PopupModal,
    ErrorModal,
  },
  data() {
    return {
      errorObj: {},
    };
  },
  computed: {
    currentRoutePath() {
      return this.$route.path;
    },
  },
  methods: {
    getImgUrl() {
      var images = require.context("../assets/images", false, /\.jpg$/);
      return images("." + this.$route.path + ".jpg");
    },
  },
};
</script>

<style scoped>
@media (max-width: 1170px) {
  .wrapper_krssu {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    position: relative;
  }
}
@media (min-width: 1171px) {
  .wrapper_krssu {
    display: flex;
    flex-direction: row;
    align-items: stretch;
  }
}

@media (max-width: 1170px) {
  .main_krssu {
    display: flex;
    flex-direction: column;
    width: 100%;
    z-index: 0;
  }
}

@media (min-width: 1171px) {
  .main_krssu {
    /* min-width: calc(theme("spacing.1") * 64); */
    display: flex;
    flex-direction: column;
  }
}

.content_krssu {
  margin-top: 93px;
}

@media (max-width: 1170px) {
  .side_krssu {
    @apply absolute top-0 right-0 bottom-0 h-full w-64 z-10;
    @apply transform transition-transform duration-200 ease-in-out translate-x-full;
  }
}

@media (min-width: 1171px) {
  .side_krssu {
    @apply w-full absolute h-screen flex top-0 bottom-0 right-0;
    @apply transform transition-transform duration-200 ease-in-out -translate-x-px;

    min-width: calc(theme("spacing.1") * 120);
    max-width: calc(theme("spacing.1") * 1000);
    /* transition: max-width 1000s ease-in-out; */
  }
}

@media (max-width: 1170px) {
  .side_krssu1 {
    @apply absolute top-0 right-0 bottom-0 h-full w-64 z-10;
    @apply transform transition-transform duration-200 ease-in-out translate-x-full;
  }
}

@media (min-width: 1171px) {
  .side_krssu1 {
    @apply w-full;
    @apply transform transition-transform duration-200 ease-in-out -translate-x-px;

    min-width: calc(theme("spacing.1") * 120);
    max-width: calc(theme("spacing.1") * 1000);
    overflow: hidden;
    /* transition: max-width 1000s ease-in-out; */
  }
}

.loader {
  border: 14px solid #f3f3f3;
  border-radius: 50%;
  border-top: 14px solid #2eafd3;
  width: 100px;
  height: 100px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  z-index: 10;
}

.black_background {
  margin-top: 60.45px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 5;
}
</style>
