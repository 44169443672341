<template>
  <div
    class="flex w-full h-full flex-col justify-center"
    style="padding-left: 88px; padding-right: 88px"
  >
    <div
      class="text-left text-sblue-600 font-bold text-3xl"
      style="font-family: Metropolis_semiBold"
    >
      {{ $t("about_title") }}
      <span
        class="text-sblue-300"
        style="
          font-family: KaushanScript;
          font-size: 2.075rem;
          line-height: 2.35rem;
        "
        >{{ $t("about_message") }}</span
      >
    </div>
    <form
      style="font-family: Metropolis_medium"
      id="about_form"
      @submit.prevent="handleSubmit"
    >
      <div
        class="
          mt-2
          md:mt-0
          flex
          items-end
          flex-col
          justify-center
          align-middle
          md:flex-row
          w-full
          md:space-x-8
          space-y-2
          md:space-y-12
          mb-2
          md:mb-4
          text-lg
        "
      >
        <div class="w-full lg:w-1/2">
          <label class="text-sgray-100"> What's your full name? </label><br />
          <div style="position: relative">
            <div
              v-if="fullName.length > 0"
              style="position: absolute; top: 35px; left: 87%"
              v-html="getSvg(colors.mainBlue, 21, 16, 'tick')"
            ></div>
            <input
              @input="trackError1"
              @blur="trackError"
              required
              v-model="fullName"
              title="Please enter your full name."
              id="name"
              type="text"
              class="form_input_krssu rounded-xl"
              placeholder="Emma"
              style="height: 60px"
              maxlength=""
              minlength="1"
            />
          </div>
        </div>
        <div class="w-full lg:w-1/2">
          <label class="text-sgray-100" style="line-height: 29px">
            What's the name of your store? </label
          ><br />
          <div style="position: relative">
            <div
              v-if="storeName.length > 0"
              style="position: absolute; top: 35px; left: 87%"
              v-html="getSvg(colors.mainBlue, 21, 16, 'tick')"
            ></div>

            <input
              title="Please enter a store name."
              @input="trackError1"
              @blur="trackError"
              required
              v-model="storeName"
              id="store"
              type="text"
              class="form_input_krssu rounded-xl"
              placeholder="The Bakery"
              style="height: 60px"
            />
          </div>
        </div>
      </div>
      <div
        class="
          mt-2
          md:mt-0
          flex
          items-end
          flex-col
          justify-center
          align-middle
          md:flex-row
          w-full
          md:space-x-8
          space-y-2
          md:space-y-12
          mb-2
          md:mb-4
          text-lg
        "
      >
        <div class="w-full lg:w-1/2">
          <label class="text-sgray-100" style="line-height: 29px">
            What's your email address? </label
          ><br />
          <div style="position: relative">
            <div
              v-if="/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)"
              style="position: absolute; top: 35px; left: 87%"
              v-html="getSvg(colors.mainBlue, 21, 16, 'tick')"
            ></div>
            <input
              required
              id="email"
              title="Please enter a valid email."
              @input="trackError1"
              @blur="trackError"
              v-model="email"
              pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
              class="form_input_krssu rounded-xl"
              placeholder="emma@thebakery.com"
              style="height: 60px"
            />
          </div>
        </div>
        <div class="w-full lg:w-1/2">
          <label class="text-sgray-100" style="line-height: 29px">
            What's your phone number? </label
          ><br />
          <div style="position: relative">
            <div
              v-if="/^\+?[1-9]\d{1,14}$/.test(phoneNumber)"
              style="position: absolute; top: 35px; left: 87%"
              v-html="getSvg(colors.mainBlue, 21, 16, 'tick')"
            ></div>
            <input
              title="Please enter a phone number."
              required
              @input="trackError1"
              @blur="trackError"
              v-model="phoneNumber"
              id="phone"
              type="text"
              pattern="^\+?[1-9]\d{1,14}$"
              class="form_input_krssu rounded-xl"
              placeholder="(555) 555-5555"
              style="height: 60px"
            />
          </div>
        </div>
      </div>

      <button
        type="submit"
        class="
          mt-14
          md:mt-24
          bg-sblue-400
          text-white text-center
          mx-auto
          button_shadow
          text-xl
          py-4
          px-24
          rounded-lg
          flex
          justify-center
          items-center
          cursor-pointer
          font-thin
          button_shadow
        "
        @click="checkError"
      >
        <div>
          {{ $t("next") }}
        </div>
        <div class="ml-4" v-html="getSvg('#fff', 18, 18, 'forwardArrow')"></div>
      </button>
    </form>
  </div>
</template>

<script>
// import Button from "@/components/Button.vue";

import { getSvg } from "../utils/getSvg";
import { getColors } from "../utils/getColors";
export default {
  name: "About yourself",
  title: "Tell us about yourself",
  components: {
    // Button,
  },
  data() {
    return {
      formValues: {},
      colors: {},
    };
  },
  computed: {
    fullName: {
      get() {
        return this.$store.state.user.first_name;
      },
      set(value) {
        this.$store.commit("changeUser", {
          key: "first_name",
          value: value,
        });
      },
    },
    email: {
      get() {
        return this.$store.state.user.email;
      },
      set(value) {
        this.$store.commit("changeUser", {
          key: "email",
          value: value,
        });
      },
    },
    phoneNumber: {
      get() {
        return this.$store.state.user.phone;
      },
      set(value) {
        this.$store.commit("changeUser", {
          key: "phone",
          value: value,
        });
      },
    },
    storeName: {
      get() {
        return this.$store.state.business.name;
      },
      set(value) {
        this.$store.commit("changeBusiness", {
          key: "name",
          value: value,
        });
      },
    },
  },
  mounted() {
    this.colors = getColors;
  },
  methods: {
    getSvg: getSvg,

    checkError() {
      var aboutForm = document.getElementById("about_form");
      if (aboutForm.checkValidity() == false) {
        var list = aboutForm.querySelectorAll(":invalid");
        for (var item of list) {
          item.classList.add("invalid_input");
        }
      }
    },
    trackError1(e) {
      if (e.target.validity.valid) {
        document.getElementById(e.target.id).classList.remove("invalid_input");
        document.getElementById(e.target.id).classList.add("valid_input");
      }
    },

    trackError(e) {
      console.log("Validity: ", e.target.validity.valid);
      if (!e.target.validity.valid) {
        document.getElementById(e.target.id).classList.add("invalid_input");
      } else {
        document.getElementById(e.target.id).classList.remove("invalid_input");
        document.getElementById(e.target.id).classList.add("valid_input");
      }
    },

    async handleSubmit() {
      console.log("Inputs: ", [...document.getElementsByTagName("input")]);
      try {
        this.$store.commit("setLoading");
        var oauthBody = {
          grant_type: "client_credentials",
          client_id: `${process.env.VUE_APP_CLIENT_ID}`,
          client_secret: `${process.env.VUE_APP_CLIENT_SECRET}`,
        };

        var oauthFormBody = [];
        for (var property in oauthBody) {
          var encodedKey = encodeURIComponent(property);
          var encodedValue = encodeURIComponent(oauthBody[property]);
          oauthFormBody.push(encodedKey + "=" + encodedValue);
        }
        oauthFormBody = oauthFormBody.join("&");

        const oauth = await fetch(
          `https://${process.env.VUE_APP_API_URL}/oauth/token`,
          {
            method: "POST",
            referrerPolicy: "no-referrer",
            headers: new Headers({
              "Content-Type": "application/x-www-form-urlencoded",
            }),
            body: oauthFormBody,
          },
        );

        const dataOauth = await oauth.json();

        console.log("Oauth: ", dataOauth);

        this.$store.commit("setAccessToken", {
          token: dataOauth.access_token,
        });

        const leads = await fetch(
          `https://${process.env.VUE_APP_API_URL}/leads`,
          {
            method: "POST",
            referrerPolicy: "no-referrer",
            headers: new Headers({
              Authorization: `Bearer ${this.$store.state.access_token}`,
              Accept: "application/vnd.kangaroorewards.api.v1+json;",
              "Content-Type": "application/json",
            }),
            body: JSON.stringify({
              business: {
                name: this.$store.state.business.name,
              },
              user: {
                first_name: this.$store.state.user.first_name,
                email: this.$store.state.user.email,
                phone: this.$store.state.user.phone,
              },
              platform_id: this.$store.state.platform_id,
              cid: this.$store.state.cid,
            }),
          },
        );

        if (!leads.ok) {
          let errorResponse = await leads.json();

          let error = {
            message: errorResponse[Object.keys(errorResponse)[0]][0],
            url: errorResponse.url,
            code: errorResponse.status,
          };
          throw error;
        }

        const dataLeads = await leads.json();

        console.log("Leads: ", dataLeads);

        this.$store.commit("changeBusiness", {
          key: "lead_id",
          value: dataLeads.data.id,
        });

        this.$router.push({ name: "About business" });
        this.$store.commit("changeUser", {
          key: "logged",
          value: true,
        });
        this.$store.commit("incrementProgress", {
          progress: 10,
        });
        this.$store.commit("resetLoading");
      } catch (error) {
        this.$store.commit("resetLoading");
        this.$store.commit("setDataError", {
          error: { message: error.message },
        });
        console.log("Error: ", error);
      }
    },
  },
};
</script>

<style scoped>
.button_shadow {
  box-shadow: 0px 20px 50px #75bdd133;
}

.form_input_krssu {
  border: 2px solid #2eafd37e;
}

.valid_input {
  border: 2px solid #2eafd37e;
  background: #2eafd308 0% 0% no-repeat padding-box;
}

.invalid_input {
  color: #d32e2e;
  border: 2px solid #d32e2e7e;
  background: #d32e2e08 0% 0% no-repeat padding-box;
}

.invalid_input input {
  color: #d32e2e;
}

.invalid_input::placeholder {
  color: #d32e2e7e m !important;
}

input.required:invalid {
  color: #d32e2e;
  border: 2px solid #d32e2e7e;
  background: #d32e2e08 0% 0% no-repeat padding-box;
}

.form_input_krssu {
  margin-top: 12px;
  color: #2eafd3;
  font-size: 1.1875rem !important;
  font-weight: bold;
  padding-left: 21px;
  padding-right: 51px;
  padding-top: auto;
  padding-bottom: auto;
  width: 100%;
}

.form_container_krssu {
  display: flex;
  justify-content: space-around;
  align-content: space-around;
  align-items: center;
}

@media screen and (max-width: 1170px) {
  .form_container_krssu {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: space-around;
    justify-content: space-around;
  }
}

@media screen and (max-width: 1170px) {
  .form_element_krssu {
    margin-top: calc(2rem * calc(1 - 0));
    margin-bottom: calc(2rem * 0);
  }
}

.form_margin_krssu {
  margin-top: 95px;
}

@media screen and (max-width: 1170px) {
  .form_margin_krssu {
    margin-top: 0px;
  }
}

@media screen and (max-width: 1440px) {
  .form_input_krssu {
    width: 100% !important;
  }
}

input {
  color: #2eafd3;
  font-weight: bold;
  padding-top: auto;
  padding-bottom: auto;
}

.form_input_krssu::placeholder {
  color: #2eafd37e;
  font-size: 1.1875rem !important;
  font-weight: bold;
  padding-top: auto;
  padding-bottom: auto;
}
</style>
