<template>
  <div class="main-kr">
    <!-- SVG backgrounds starts -->
    <div class="svg-bg-kr">
      <svg
        v-for="n in 9"
        :key="n"
        xmlns="http://www.w3.org/2000/svg"
        width="96px"
        height="96px"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
        />
      </svg>
    </div>
    <!-- SVG backgrounds ends -->

    <!-- PWP rewards starts -->
    <div class="container-kr">
      <!-- ratio description starts -->
      <div
        v-if="this.$store.state.ew_redeemingPwp"
        class="ratio-desc-container-kr"
      >
        <div class="description-kr">
          {{ selectedPts }}
          {{ this.$store.state.ew_translations.ptsLabel }}
          {{ this.$store.state.ew_translations.willEarnYou }}
        </div>
      </div>
      <!-- ratio description ends -->
      <!-- amount in $ starts -->
      <div class="amount-container-kr">
        <div class="amount-kr">${{ selectedBalance }}</div>
      </div>
      <!-- amount in $ ends -->
      <!-- range slider starts -->

      <div class="range-kr">
        <div class="sliderValue-kr">
          <span id="selected_pts" class="arrow-bottom-kr">
            {{
              this.$store.state.ew_redeemingPwp
                ? `${selectedPts} ${this.$store.state.ew_translations.ptsLabel}`
                : `${this.$store.state.ew_translations.currencySymbol} ${selectedBalance}`
            }}
          </span>
        </div>
        <input
          :style="cssAtts"
          id="pwp_range"
          class="field-kr"
          type="range"
          :min="
            this.$store.state.ew_redeemingPwp
              ? this.$store.state.ew_pwpSettings.min
              : this.$store.state.ew_pwcSettings.min
          "
          :max="
            this.$store.state.ew_redeemingPwp
              ? this.$store.state.ew_pwpSettings.max
              : this.$store.state.ew_pwcSettings.max
          "
          :value="selectedPts"
          @input="evInp()"
          @blur="evBlu()"
          step="1"
        />
      </div>

      <!-- range slider ends -->
      <!-- redeem button starts -->
      <div
        @click="null"
        :class="[
          'not-redeemable-btn-kr btn-kr ew_btn-ew_bg-primary-kr ew_btn-primary-kr',
        ]"
        v-html="
          this.$store.state.ew_minorLoading
            ? `<div class='btn-kr-loader'></div>`
            : this.$store.state.ew_translations.confirmRedemption
        "
      ></div>
      <!-- redeem button ends -->
    </div>
    <!-- PWP rewards ends -->
  </div>
</template>

<script>
export default {
  name: "PwpcRedemption",
  emits: ["redeem"],
  data() {
    return {
      selectedPts: this.$store.state.ew_redeemingPwp
        ? this.$store.state.ew_pwpSettings.max
        : this.$store.state.ew_pwcSettings.max,
      selectedBalance: this.$store.state.ew_redeemingPwp
        ? parseFloat(this.$store.state.ew_pwpSettings.max) /
          parseFloat(this.$store.state.ew_pwpSettings.ratio)
        : parseFloat(this.$store.state.ew_pwcSettings.max),
    };
  },

  computed: {
    cssAtts() {
      return {
        "--position": `${
          this.$store.state.ew_redeemingPwp
            ? ((this.selectedPts - this.$store.state.ew_pwpSettings.min) /
                (this.$store.state.ew_pwpSettings.max -
                  this.$store.state.ew_pwpSettings.min)) *
              100
            : ((this.selectedPts - this.$store.state.ew_pwcSettings.min) /
                (this.$store.state.ew_pwcSettings.max -
                  this.$store.state.ew_pwcSettings.min)) *
              100
        }%`,
      };
    },
  },

  mounted() {
    this.selectedPts = this.$store.state.ew_redeemingPwp
      ? this.$store.state.ew_pwpSettings.max
      : this.$store.state.ew_pwcSettings.max;
    this.selectedBalance = this.$store.state.ew_redeemingPwp
      ? parseFloat(this.$store.state.ew_pwpSettings.max) /
        parseFloat(this.$store.state.ew_pwpSettings.ratio)
      : parseFloat(this.$store.state.ew_pwcSettings.max);
  },

  methods: {
    evInp() {
      let value = document.getElementById("pwp_range").value;
      this.selectedPts = document.getElementById("pwp_range").value;
      if (this.$store.state.ew_redeemingPwp) {
        document.getElementById(
          "selected_pts",
        ).textContent = `${value} ${this.$store.state.ew_translations.ptsLabel}`;
      } else {
        document.getElementById(
          "selected_pts",
        ).textContent = `${this.$store.state.ew_translations.currencySymbol} ${value}`;
      }

      if (this.$store.state.ew_redeemingPwp) {
        document.getElementById("selected_pts").style.left =
          ((value - this.$store.state.ew_pwpSettings.min) /
            (this.$store.state.ew_pwpSettings.max -
              this.$store.state.ew_pwpSettings.min)) *
            100 -
          10 +
          "%";
      } else {
        document.getElementById("selected_pts").style.left =
          ((value - this.$store.state.ew_pwcSettings.min) /
            (this.$store.state.ew_pwcSettings.max -
              this.$store.state.ew_pwcSettings.min)) *
            100 -
          10 +
          "%";
      }
      document.getElementById("selected_pts").classList.add("show");
      document.getElementById("pwp_range").classList.add("pressed");
      if (this.$store.state.ew_redeemingPwp) {
        document.getElementById(
          "pwp_range",
        ).style.background = `linear-gradient(to right, var(--icon_primary_color) 0%, var(--icon_primary_color)
        ${
          ((this.selectedPts - this.$store.state.ew_pwpSettings.min) /
            (this.$store.state.ew_pwpSettings.max -
              this.$store.state.ew_pwpSettings.min)) *
          100
        }%, var(--icon_bg_primary_color) ${
          ((this.selectedPts - this.$store.state.ew_pwpSettings.min) /
            (this.$store.state.ew_pwpSettings.max -
              this.$store.state.ew_pwpSettings.min)) *
          100
        }%, var(--icon_bg_primary_color) 100%)`;
      } else {
        document.getElementById(
          "pwp_range",
        ).style.background = `linear-gradient(to right, var(--icon_primary_color) 0%, var(--icon_primary_color)
        ${
          ((this.selectedPts - this.$store.state.ew_pwcSettings.min) /
            (this.$store.state.ew_pwcSettings.max -
              this.$store.state.ew_pwcSettings.min)) *
          100
        }%, var(--icon_bg_primary_color)  ${
          ((this.selectedPts - this.$store.state.ew_pwcSettings.min) /
            (this.$store.state.ew_pwcSettings.max -
              this.$store.state.ew_pwcSettings.min)) *
          100
        }%, var(--icon_bg_primary_color) 100%)`;
      }
      if (this.$store.state.ew_redeemingPwp) {
        this.selectedBalance =
          document.getElementById("pwp_range").value /
          this.$store.state.ew_pwpSettings.ratio;
      } else {
        this.selectedBalance = document.getElementById("pwp_range").value;
      }
    },
    evBlu() {
      document.getElementById("selected_pts").classList.remove("show");
      document.getElementById("pwp_range").classList.remove("pressed");
    },
  },
};
</script>

<style scoped>
.main-kr {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.svg-bg-kr {
  display: grid;
  position: absolute;
  left: -32px;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  column-gap: 2px;
  row-gap: 2px;
  opacity: 0.1;
  z-index: 0;
  transform: rotate(9deg);
}

.container-kr {
  width: 100%;
  height: 100%;
  opacity: 1;
  z-index: 10;
  display: flex;
  flex-direction: column;
  padding: 12px;
}

.ratio-desc-container-kr {
  display: flex;
  justify-content: center;
}

.description-kr {
  text-align: center;
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 16px;
}

.amount-container-kr {
  display: flex;
  justify-content: center;
  margin-top: 16px;
  margin-bottom: 8px;
}

.amount-kr {
  text-align: center;
  font-weight: 700;
  font-weight: 700;
  font-size: 30px;
  line-height: 36px;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.range-kr {
  width: 100%;
  margin-top: auto;
  margin-bottom: auto;
  padding-top: 40px;
}

.sliderValue-kr {
  position: relative;
  width: 100%;
}

.sliderValue-kr span {
  height: 30px;
  width: 57px;
  color: var(--value_primary_color);
  font-size: 11px;
  line-height: 13px;
  position: absolute;
  transform: translateX(0%) scale(0);
  font-weight: 500;
  top: -60px;
  z-index: 2;
  transform-origin: bottom;
  transition: transform 0.3s ease-in-out;
}

.sliderValue-kr span.show {
  transform: translateX(0%) scale(1);
}

.sliderValue-kr span {
  position: absolute;
  text-align: center;
  content: "";
  height: 32px;
  width: 57px;
  background: var(--value_bg_primary_color);
  border-radius: 5px !important;
  z-index: -1;
  transform: translateX(-40%) scale(0);
  left: 0%;
}

.field-kr {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: relative;
}

.range-kr input {
  -webkit-appearance: none;
  width: 100%;
  height: 8px;
  background: linear-gradient(
    to right,
    var(--icon_primary_color) 0%,
    var(--icon_primary_color) var(--position),
    var(--icon_bg_primary_color) var(--position),
    var(--icon_bg_primary_color) 100%
  );
  border-radius: 5px !important;
  outline: none;
  border: none;
  z-index: 2222;
}

.range-kr input::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 28px;
  height: 28px;
  border-radius: 50% !important;
  background: var(--icon_primary_color);
  border: 1px solid var(--icon_primary_color);
  cursor: pointer;
}

.range-kr input.pressed::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 22px;
  height: 22px;
  border-radius: 50% !important;
  background: var(--icon_primary_color);
  box-shadow: 0 0 0 5pt rgba(255, 255, 255, 0.2);
  cursor: pointer;
}

.range-kr input::-moz-range-thumb {
  -webkit-appearance: none;
  width: 28px;
  height: 28px;
  border-radius: 50% !important;
  background: var(--icon_primary_color);
  border: 1px solid var(--icon_primary_color);
  cursor: pointer;
}

.range-kr input::-moz-range-progress {
  background: var(--icon_primary_color);
}

.arrow-bottom-kr {
  display: flex;
  align-items: center;
  justify-content: center;
}

.arrow-bottom-kr.show {
  transform: translateX(0%) scale(1);
}

.arrow-bottom-kr:after {
  transform: translateX(0%) scale(1);
  content: " ";
  position: absolute;
  bottom: -9px;
  border-top: 9px solid var(--value_bg_primary_color);
  border-right: 9px solid transparent;
  border-left: 9px solid transparent;
  border-bottom: none;
}

.not-redeemable-btn-kr {
  cursor: not-allowed;
}
</style>
