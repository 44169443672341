<template>
  <div class="main-kr">
    <div>
      <div>
        <div class="title-kr">Profile</div>
        <div class="form-kr">
          <input
            class="input-kr inactive-input"
            placeholder="First Name"
            type="text"
            readonly="readonly"
            :value="this.$store.state.ew_user.firstName"
          />
          <input
            class="input-kr inactive-input"
            placeholder="Email"
            type="text"
            readonly="readonly"
            :value="this.$store.state.ew_user.email"
          />
          <input
            class="input-kr date-kr"
            type="date"
            id="date"
            v-model="birthDate"
          />
        </div>
      </div>
      <div class="title-kr">Communication Preferences</div>
      <div class="consent-check-kr">
        <consent-check
          v-for="consent in consentArray"
          :key="consent.id"
          :inputValue="consent.id"
          v-model="selected"
          :description="consent.description"
          :checked="consent.checked"
        />
      </div>

      <!-- save button starts -->
      <div
        @click="saveSettings(selected)"
        :class="[
          this.$store.state.ew_minorLoading ? 'loading-btn-kr' : '',
          'btn-kr ew_btn-ew_bg-primary-kr ew_btn-primary-kr',
        ]"
        v-html="
          this.$store.state.ew_minorLoading
            ? `<div class='btn-kr-loader'></div>`
            : this.$store.state.ew_translations.save
        "
      ></div>

      <!-- save button ends -->
    </div>
  </div>
</template>

<script>
import ConsentCheck from "../components/ConsentCheck.vue";
import { replaceValues } from "../utils/formatString";
import moment from "moment";

export default {
  name: "SettingsTab",
  components: {
    ConsentCheck,
  },
  props: ["consentArray", "selectedConsents"],
  data() {
    return {
      selected: this.selectedConsents,
      birthDate: moment(this.$store.state.ew_user.dob).format("L"),
    };
  },

  mounted() {
    var year = new Date().getFullYear();
    document.getElementById("date").setAttribute("min", year + "-01-01");
    document.getElementById("date").setAttribute("max", year + "-12-31");
  },

  methods: {
    replaceValues: replaceValues,

    async saveSettings() {
      this.$store.commit("enableMinorLoading");

      setTimeout(() => {
        this.$store.commit("disableMinorLoading");
      }, 1000);

      setTimeout(() => {
        this.$store.commit("showNotification", {
          type: "success",
          title: "Settings saved",
        });
      }, 1000);

      setTimeout(() => {
        this.$store.commit("closeNotification");
      }, 5000);
    },
  },
};
</script>

<style scoped>
.main-kr {
  padding-bottom: 16px;
  width: 100%;
  height: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  position: relative;
}

.title-kr {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 8px;
  font-weight: 700;
}

.form-kr {
  margin-bottom: 16px;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  flex-direction: column;
}

.form-kr:last-child {
  margin-bottom: 0px !important;
}

.input-kr {
  color: rgba(107, 114, 128, 1);
  border-width: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 8px;
  padding-left: 16px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 6px;
  margin-left: 32px;
  margin-right: 32px;
  box-shadow: 0px 3px 10px #00000029;
  padding-right: 6px;
}

.inactive-input {
  cursor: not-allowed;
}

.consent-check-kr {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 16px;
  margin-right: 16px;
}

.hidden-btn-kr {
  opacity: 0;
  width: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
  font-weight: 700;
  border-radius: 12px !important;
}

.date-kr {
  background-color: rgba(255, 255, 255, 1);
  color: rgba(107, 114, 128, 1);
}
</style>