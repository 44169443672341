export default {
  "languages": {
    "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
    "french": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["French"])}
  },
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hello i18n !!"])},
  "welcome_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to Kangaroo Rewards!"])},
  "welcome_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create your loyalty program in a few quick steps"])},
  "get_started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let’s Get Started"])},
  "about_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First things first… Tell us about"])},
  "about_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yourself"])},
  "first_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What's your first name?"])},
  "second_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What's the name of your store?"])},
  "email_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What's your email address?"])},
  "phone_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What's your phone number?"])},
  "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
  "setup_tile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let’s set up your loyalty program!"])},
  "setup_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customize your program in 3 easy steps in less than 2 minutes!"])},
  "first_step_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let's customize your program to match your brand!"])},
  "second_step_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let's set up your referral program!"])},
  "third_step_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let's configure how you reward your customers!"])},
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
  "sdk_customize_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let’s set up your loyalty rewards program!"])},
  "sdk_customize_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Which type of integration will you use?"])},
  "widget": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Widget"])},
  "loyalty_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loyalty Page"])},
  "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
  "widget_customize_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excellent! Our widget is easy to customize"])},
  "widget_customize_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have 3 styles for you to choose from"])},
  "full": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full"])},
  "wave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wave"])},
  "card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Card"])},
  "customize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customize"])},
  "widget_customize1_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let’s design your loyalty widget!"])},
  "widget_customize1_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose your brand colors and get noticed"])},
  "background": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Background"])},
  "widget_launcher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Widget Launcher"])},
  "img_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload image"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
  "referral_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Launch your referral program!"])},
  "referral_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snowball your growth with customer referrals"])},
  "referral1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My ambassadors will earn"])},
  "referral2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New customers will earn"])},
  "referral3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referral must be completed in"])},
  "points": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["points"])},
  "points_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pts"])},
  "birthdays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birthdays"])},
  "first_visit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Visit"])},
  "mondays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mondays"])},
  "Tuesdays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuesdays"])},
  "Wednesdays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wednesdays"])},
  "Thursdays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thursdays"])},
  "Fridays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fridays"])},
  "Saturdays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saturdays"])},
  "Sundays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sundays"])},
  "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["days"])},
  "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount"])},
  "free_shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free shipping"])},
  "reward_customers_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loyalty works better with point rewards!"])},
  "reward_customers_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make everyday shopping more rewarding"])},
  "reward_customers2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When my customers spend"])},
  "reward_customers1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I want to give them "])},
  "dollar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dollar"])},
  "offers_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surprise customers every step of the way!"])},
  "offers_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create your reward catalog"])},
  "offers_title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make customers happy"])},
  "offers_subtitle1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reward the right customers at the right time"])},
  "end_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You’re All Set!"])},
  "end_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enjoy your 30-Day Free Trial!"])},
  "launch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Launch Loyalty Program"])},
  "finish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finish"])}
}