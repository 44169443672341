<template>
  <div class="main-kr" style="">
    <!-- redeem amount starts -->
    <div class="amount-kr">
      <div>
        {{ this.$store.state.ew_translations.youRedeemed }}: {{ amount }}
      </div>
    </div>
    <!-- redeem amount ends -->
    <!-- code + apply starts -->

    <div style="margin-top: 32px">
      <!-- code  starts -->
      <div class="title-kr" style="">Your coupon code:</div>

      <div class="container-kr">
        <div class="ew_border-primary-kr code-kr">
          <div>
            {{ code }}
          </div>
          <input id="redemption-code" type="hidden" :value="code" />
          <div
            class="icon-kr"
            @click="copyText"
            v-html="
              getSvg(
                this.$store.state.ew_settings.iconPrimaryColor,
                17.08,
                18.98,
                'copy',
              )
            "
          ></div>
        </div>
        <div
          @click="$emit('apply')"
          class="btn-kr btn-kr ew_btn-ew_bg-primary-kr ew_btn-primary-kr"
        >
          Apply to cart
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getSvg } from "../../utils/svgLibrary";

export default {
  name: "RedemptionTerms",
  props: ["code", "amount"],
  emits: ["apply"],
  data() {
    return {
      textCopied: false,
    };
  },
  methods: {
    getSvg: getSvg,
    copyText() {
      const textToCopy = document.querySelector("#redemption-code");
      textToCopy.setAttribute("type", "text");
      textToCopy.select();

      try {
        var successful = document.execCommand("copy");
        this.textCopied = successful;
        setInterval(() => {
          this.textCopied = false;
        }, 1000);
        this.$store.commit("showNotification", {
          type: "success",
          title: this.$store.state.ew_translations.copied,
        });
        setTimeout(() => {
          this.$store.commit("closeNotification");
        }, 5000);
      } catch (error) {
        this.$store.commit("showNotification", {
          type: "error",
          title: "Copy failed",
        });
        setTimeout(() => {
          this.$store.commit("closeNotification");
        }, 5000);
      }
      textToCopy.setAttribute("type", "hidden");
      window.getSelection().removeAllRanges();
    },
  },
};
</script>

<style scoped>
.main-kr {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.amount-kr {
  display: flex;
  justify-content: center;
  font-weight: 700;
}

.title-kr {
  display: flex;
  justify-content: flex-start;
  font-size: 14px;
  line-height: 20px;
}

.container-kr {
  display: flex;
  margin-top: 8px;
  flex-direction: column;
}

.code-kr {
  align-items: center;
  justify-content: space-around;
  padding: 8px;
  margin-bottom: 16px;
  border-radius: 12px !important;
  font-weight: 700;
  display: flex;
  border-style: dashed;
  border-width: 1px;
}

.icon-kr {
  cursor: pointer;
}
</style>