<template>
  <div>
    <div class="flex justify-center mx-2">
      <div class="inline-block ">
        <div
          class="text-center grid place-items-center"
          style="margin-top: 24.5px; margin-bottom: 24.5px; "
        >
          {{ description }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ErrorModal",
  props: ["description"],
  emits: ["redeem-action"],
};
</script>

<style scoped></style>
