export const getSvg = (color, width, height, key) => {
  switch (key) {
    case "forwardArrow":
      return `
      <svg width=${width} height=${height} viewBox="0 0 22.237 22.237">
      <path
        id="icon"
        d="M0,12.386V0H13.063"
        transform="translate(20.116 10.879) rotate(135)"
        fill="none"
        stroke="${color}"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="3"
      />
    </svg>
    `;
    case "backArrow":
      return `
      <svg width=${width} height=${height} 
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 22.237 22.237"
    >
      <path
        id="icon"
        d="M0,0V12.386H13.063"
        transform="translate(10.879 2.121) rotate(45)"
        fill="none"
        stroke="${color}"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="3"
      />
    </svg>
      `;
    case "tick":
      return `
      <svg
      width=${width}
      height=${height}
      viewBox="0 0 27.414 21.337"
    >
      <path
        id="Path_4"
        data-name="Path 4"
        d="M29.5,7.685l-13.4,13.4-4.426,4.426L3.5,17.342l2.095-2.095,6.077,6.062L27.405,5.59Z"
        transform="translate(-2.793 -4.883)"
        fill=${color}
        stroke=${color}
        stroke-width="1"
      />
    </svg>`;
    case "magicWand":
      return `
      <svg
  xmlns="http://www.w3.org/2000/svg"
  width=${width}
  height=${height}
  viewBox="0 0 23.31 23.31"
>
  <path
    id="Path_15"
    data-name="Path 15"
    d="M8.214,6,5.44,7.549,6.994,4.775,5.44,2,8.214,3.554,10.989,2,9.435,4.775l1.554,2.775L8.214,6M21.533,16.872l2.775-1.554-1.554,2.775,1.554,2.775-2.775-1.554-2.775,1.554,1.554-2.775-1.554-2.775,2.775,1.554M24.308,2,22.754,4.775l1.554,2.775L21.533,6,18.758,7.549l1.554-2.775L18.758,2l2.775,1.554L24.308,2M14.7,13.964,17.4,11.256,15.051,8.9l-2.708,2.708L14.7,13.964m1.143-6.093,2.6,2.6a1.105,1.105,0,0,1,0,1.565L5.484,24.986a1.105,1.105,0,0,1-1.565,0l-2.6-2.6a1.105,1.105,0,0,1,0-1.565L14.274,7.871A1.105,1.105,0,0,1,15.839,7.871Z"
    transform="translate(-0.997 -2)"
    fill=${color}
  />
</svg>
`;
    case "step1":
      return `
  <svg
  xmlns="http://www.w3.org/2000/svg"
  width=${width}
  height=${height}
  viewBox="0 0 33.208 33.208"
>
  <path
    id="icon"
    d="M0,18.964V0H20"
    transform="translate(30.38 16.238) rotate(135)"
    fill="none"
    stroke=${color}
    stroke-linecap="round"
    stroke-linejoin="round"
    stroke-width="4"
  />
</svg>
  `;
    case "step2":
      return `
      <svg
      width=${width}
      height=${height}
      viewBox="0 0 33.208 33.208"
    >
      <path
        id="icon"
        d="M0,18.964V0H20"
        transform="translate(30.38 16.238) rotate(135)"
        fill="none"
        stroke=${color}
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="4"
      />
    </svg>
      `;
    case "step3":
      return `
        <svg

        width=${width}
        height=${height}
        viewBox="0 0 33.208 33.208"
      >
        <path
          id="icon"
          d="M0,18.964V0H20"
          transform="translate(30.38 16.238) rotate(135)"
          fill="none"
          stroke=${color}
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="4"
        />
      </svg>
        `;
    case "done":
      return `
        <svg

        width=${width}
        height=${height}
        viewBox="0 0 66 66"
      >
        <g id="check" transform="translate(-1031 -431)">
          <circle
            id="Ellipse_4"
            data-name="Ellipse 4"
            cx="33"
            cy="33"
            r="33"
            transform="translate(1031 431)"
            fill=${color}
          />
          <path
            id="Path_5"
            data-name="Path 5"
            d="M29.5,7.685,11.671,25.513,3.5,17.342l2.095-2.095,6.077,6.062L27.405,5.59Z"
            transform="translate(1048 448.91)"
            fill="#fff"
            stroke="#fff"
            stroke-width="1"
          />
        </g>
      </svg>
        `;
    case "edit":
      return `
          <svg
  
          width=${width}
          height=${height}
          viewBox="0 0 17.342 17.342"
        >
          <path
            id="Path_16"
            data-name="Path 16"
            d="M20.06,6.892a.959.959,0,0,0,0-1.358L17.806,3.279a.959.959,0,0,0-1.358,0L14.675,5.042l3.612,3.612M3,16.727v3.612H6.612L17.266,9.676,13.654,6.063Z"
            transform="translate(-3 -2.997)"
            fill=${color}
          />
        </svg>
          `;
    case "delete":
      return `
            <svg
    
            width=${width}
          height=${height}
            viewBox="0 0 13.486 17.339"
          >
            <path
              id="Path_17"
              data-name="Path 17"
              d="M18.486,3.963H15.114L14.151,3H9.335l-.963.963H5V5.89H18.486M5.963,18.413A1.927,1.927,0,0,0,7.89,20.339H15.6a1.927,1.927,0,0,0,1.927-1.927V6.853H5.963Z"
              transform="translate(-5 -3)"
              fill=${color}
            />
          </svg>
            `;
    case "save":
      return `
      <svg
      width=${width}
      height=${height}
      viewBox="0 0 24 24"
      fill=${color}
    >
      <path
        d="M15,9H5V5H15M12,19A3,3 0 0,1 9,16A3,3 0 0,1 12,13A3,3 0 0,1 15,16A3,3 0 0,1 12,19M17,3H5C3.89,3 3,3.9 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V7L17,3Z"
      />
    </svg>
      `;
    case "moneyBag":
      return `
          <svg
          width=${width}
          height=${height}
          viewBox="0 0 53.514 59.05"
        >
          <g id="type" transform="translate(-959.148 -432.925)">
            <path
              id="sack-dollar"
              d="M42,16.763,47.91,2,36.1,7.905,30.195,2,24.29,7.905,12.48,2l5.905,14.763ZM56.767,49.24S53.815,28.573,42,22.668H18.385C6.575,28.573,3.622,49.24,3.622,49.24S.67,61.05,18.385,61.05H42C59.72,61.05,56.767,49.24,56.767,49.24ZM30.49,40.087c3.543.886,7.972,1.772,7.972,6.2,0,2.953-2.362,5.315-5.905,5.905v3.543H27.537V52.193c-3.543-.886-5.905-3.248-5.905-5.905h3.543c0,1.772,2.362,3.248,5.019,3.248s5.019-1.476,5.019-3.248-1.772-2.362-5.61-3.248c-3.543-.886-7.972-1.772-7.972-6.2,0-2.953,2.362-5.315,5.905-5.905V27.392h5.019v3.543c3.543.886,5.905,3.248,5.905,5.905H34.919c0-1.772-2.362-3.248-5.019-3.248S24.88,35.068,24.88,36.84C25.175,38.611,26.947,39.5,30.49,40.087Z"
              transform="translate(955.711 430.925)"
              fill=${color}
            />
          </g>
        </svg>
          `;
    case "discountBag":
      return `
            <svg
    
            width=${width}
            height=${height}
            viewBox="0 0 52.964 58.443"
          >
            <g id="type" transform="translate(0)">
              <path
                id="sack-percent"
                d="M18.231,16.611,12.387,2,24.075,7.844,29.919,2l5.844,5.844L47.452,2,41.608,16.611H18.231M41.608,60.443H18.231C.7,60.443,3.62,48.754,3.62,48.754S6.542,28.3,18.231,22.455H41.608c11.689,5.844,14.611,26.3,14.611,26.3s2.922,11.689-14.611,11.689M16.77,32.683a4.494,4.494,0,0,0,4.383,4.383,4.383,4.383,0,1,0,0-8.766,4.494,4.494,0,0,0-4.383,4.383m26.3,17.533A4.383,4.383,0,1,0,38.686,54.6a4.494,4.494,0,0,0,4.383-4.383m0-17.825L38.978,28.3,16.77,50.508,20.861,54.6Z"
                transform="translate(-3.438 -2)"
                fill=${color}
              />
            </g>
          </svg>
            `;
    case "truck":
      return `
              <svg
              width=${width}
              height=${height}
              viewBox="0 0 54.19 39.411"
            >
              <g id="type" transform="translate(-962.473 -442.863)">
                <path
                  id="Path_18"
                  data-name="Path 18"
                  d="M42.874,39.716a3.695,3.695,0,1,1,3.695-3.695,3.695,3.695,0,0,1-3.695,3.695m3.695-22.169L51.4,23.705H40.411V17.547M13.316,39.716a3.695,3.695,0,1,1,3.695-3.695,3.695,3.695,0,0,1-3.695,3.695M47.8,13.853h-7.39V4H5.926A4.909,4.909,0,0,0,1,8.926V36.021H5.926a7.39,7.39,0,0,0,14.779,0H35.484a7.39,7.39,0,0,0,14.779,0H55.19V23.705Z"
                  transform="translate(961.473 438.863)"
                  fill=${color}
                />
              </g>
            </svg>
              `;
    case "birthdayCake":
      return `
              <svg
              width=${width}
              height=${height}
              viewBox="0 0 43.98 53.753"
            >
              <g id="type" transform="translate(-963.578 -435.691)">
                <path
                  id="Path_22"
                  data-name="Path 22"
                  d="M24.99,14.66a4.885,4.885,0,0,0,4.887-4.887,4.651,4.651,0,0,0-.709-2.517L24.99,0,20.812,7.257A4.651,4.651,0,0,0,20.1,9.773,4.887,4.887,0,0,0,24.99,14.66M36.229,39.093l-2.614-2.639-2.639,2.639a8.706,8.706,0,0,1-11.948,0l-2.59-2.639-2.688,2.639A8.471,8.471,0,0,1,3,40.046V51.31a2.443,2.443,0,0,0,2.443,2.443H44.536A2.443,2.443,0,0,0,46.98,51.31V40.046a8.471,8.471,0,0,1-10.751-.953m3.421-17.1H27.433V17.1H22.547V21.99H10.33A7.33,7.33,0,0,0,3,29.32v3.763a4.8,4.8,0,0,0,4.789,4.789,4.53,4.53,0,0,0,3.372-1.393l5.278-5.2,5.155,5.2a4.9,4.9,0,0,0,6.768,0l5.18-5.2,5.253,5.2a4.608,4.608,0,0,0,3.372,1.393,4.818,4.818,0,0,0,4.813-4.789V29.32A7.33,7.33,0,0,0,39.65,21.99Z"
                  transform="translate(960.578 435.691)"
                  fill=${color}
                />
              </g>
            </svg>
              `;
    case "store":
      return `
                <svg
                width=${width}
                height=${height}
                viewBox="0 0 52.839 46.968"
              >
                <g id="type" transform="translate(-959.148 -439.084)">
                  <path
                    id="Path_21"
                    data-name="Path 21"
                    d="M29.419,45.1H11.806V33.355H29.419m26.419,0V27.484L52.9,12.806H5.935L3,27.484v5.871H5.935V50.968H35.29V33.355H47.032V50.968H52.9V33.355M52.9,4H5.935V9.871H52.9Z"
                    transform="translate(956.148 435.084)"
                    fill=${color}
                  />
                </g>
              </svg>
                `;
    case "calendarHour":
      return `
      <svg
      width=${width}
      height=${height}
      viewBox="0 0 46.204 50.824"
    >
      <g id="type" transform="translate(-962.466 -437.156)">
        <path
          id="Path_23"
          data-name="Path 23"
          d="M30.722,28.722h3.465v6.515l5.637,3.257-1.733,3-7.37-4.251V28.722m9.241-11.551H7.62V42.583H18.409A16.193,16.193,0,0,1,39.963,21.029V17.171M7.62,47.2A4.619,4.619,0,0,1,3,42.583V10.241A4.6,4.6,0,0,1,7.62,5.62h2.31V1h4.62V5.62H33.033V1h4.62V5.62h2.31a4.62,4.62,0,0,1,4.62,4.62V24.333A16.173,16.173,0,0,1,21.713,47.2H7.62M33.033,24.448a11.2,11.2,0,1,0,11.2,11.2A11.2,11.2,0,0,0,33.033,24.448Z"
          transform="translate(959.466 436.156)"
          fill=${color}
        />
      </g>
    </svg>
      `;
    case "drop":
      return `
    <svg
    width=${width}
    height=${height}
    viewBox="0 0 23.738 33.134"
  >
    <path
      id="Path_6"
      data-name="Path 6"
      d="M17.869,36.384A11.869,11.869,0,0,1,6,24.515C6,16.6,17.869,3.25,17.869,3.25S29.738,16.6,29.738,24.515A11.869,11.869,0,0,1,17.869,36.384Z"
      transform="translate(-6 -3.25)"
      fill=${color}
    />
  </svg>
        `;
    case "paint":
      return ` 
          <svg
          xmlns="http://www.w3.org/2000/svg"
          width=${width}
          height=${height}
          viewBox="0 0 34.823 32.884"
        >
          <path
            id="Path_7"
            data-name="Path 7"
            d="M33.952,22.245s-3.869,4.2-3.869,6.77a3.869,3.869,0,1,0,7.737,0c0-2.573-3.869-6.77-3.869-6.77m-26.675-2.9,9.265-9.265,9.265,9.265m3.424-2.05L11.939,0,9.212,2.727l4.6,4.6L3.853,17.293a2.884,2.884,0,0,0,0,4.1L14.492,32.033a2.9,2.9,0,0,0,4.1,0L29.232,21.394A2.884,2.884,0,0,0,29.232,17.293Z"
            transform="translate(-2.997)"
            fill=${color}
          />
        </svg>
          `;
    case "uploadCloud":
      return `
      <svg
      xmlns="http://www.w3.org/2000/svg"
      width=${width}
      height=${height}
      viewBox="0 0 35.606 23.738"
    >
      <path
        id="Path_9"
        data-name="Path 9"
        d="M20.77,17.352v5.934H14.836V17.352H10.385L17.8,9.934l7.418,7.418m3.486-4.406A11.123,11.123,0,0,0,7.937,9.979,8.906,8.906,0,0,0,8.9,27.738H28.188a7.4,7.4,0,0,0,.519-14.791Z"
        transform="translate(0 -4)"
        fill=${color}
      />
    </svg>
      `;
    case "image":
      return `
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width=${width}
        height=${height}
        viewBox="0 0 23.738 23.738"
      >
        <path
          id="Path_8"
          data-name="Path 8"
          d="M10.253,16.847l3.3,3.956,4.616-5.934L24.1,22.781H5.638m21.1,1.319V5.638A2.637,2.637,0,0,0,24.1,3H5.638A2.638,2.638,0,0,0,3,5.638V24.1a2.638,2.638,0,0,0,2.638,2.638H24.1A2.638,2.638,0,0,0,26.738,24.1Z"
          transform="translate(-3 -3)"
          fill=${color}
        />
      </svg>
        `;
    case "text":
      return `
      <svg
      xmlns="http://www.w3.org/2000/svg"
      width=${width}
        height=${height}
      viewBox="0 0 23.058 22.576"
    >
      <path
        id="Path_12"
        data-name="Path 12"
        d="M25.652,4,27.4,10.547l-1.445.391a11.269,11.269,0,0,0-2.167-3.281,3.894,3.894,0,0,0-2.649-.647H17.374v15.8c0,.753,0,1.505.5,1.881a4.838,4.838,0,0,0,2.513.376v1.505h-9.03V25.071a4.838,4.838,0,0,0,2.513-.376c.5-.376.5-1.129.5-1.881V7.01H10.6a3.894,3.894,0,0,0-2.649.647,11.269,11.269,0,0,0-2.167,3.281L4.34,10.547,6.086,4Z"
        transform="translate(-4.34 -4)"
        fill=${color}
      />
    </svg>
      `;
    case "textEdit":
      return `
      <svg
      xmlns="http://www.w3.org/2000/svg"
      width=${width}
      height=${height}
      viewBox="0 0 30.615 21.576"
    >
      <path
        id="Path_13"
        data-name="Path 13"
        d="M2,6V9.082H20.494V6H2m0,6.165v3.082H20.494V12.165H2m27.8.2a.856.856,0,0,0-.6.262L27.66,14.168l3.159,3.159,1.541-1.541a.819.819,0,0,0,0-1.187l-1.973-1.973a.822.822,0,0,0-.586-.262m-3.036,2.7L17.411,24.4v3.175h3.175l9.339-9.355-3.159-3.159M2,18.329v3.082H14.329V18.329Z"
        transform="translate(-2 -6)"
        fill=${color}
      />
    </svg>

      `;
    case "gift":
      return `
      <svg
      xmlns="http://www.w3.org/2000/svg"
      width=${width}
      height=${height}
      stroke=${color}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M12 8v13m0-13V6a2 2 0 112 2h-2zm0 0V5.5A2.5 2.5 0 109.5 8H12zm-7 4h14M5 12a2 2 0 110-4h14a2 2 0 110 4M5 12v7a2 2 0 002 2h10a2 2 0 002-2v-7"
      />
    </svg> 
      `;
    case "X":
      return `
      <svg
      xmlns="http://www.w3.org/2000/svg"
      width=${width}
      height=${height}
      fill="none"
      viewBox="0 0 24 24"
      stroke=${color}
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M6 18L18 6M6 6l12 12"
      />
    </svg>
      `;
    default:
      break;
  }
};
