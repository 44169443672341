<template>
  <div class="main-kr">
    <!-- title starts -->
    <div class="title-kr" style="">
      {{
        this.$store.state.ew_redeemingPwp || this.$store.state.ew_redeemingPwc
          ? this.$store.state.ew_translations.personalizeReward
          : this.$store.state.ew_selectedRedemption.showingTitle
      }}
    </div>
    <!-- title ends -->
    <div
      :class="[
        (this.$store.state.ew_redeemingPwp ||
          this.$store.state.ew_redeemingPwc) &&
        !redeemed
          ? ''
          : 'container-kr ',
        'main-container-kr',
      ]"
    >
      <redemption-info
        v-if="
          !this.$store.state.ew_checkingTerms &&
          !(
            this.$store.state.ew_redeemingPwp ||
            this.$store.state.ew_redeemingPwc
          ) &&
          !redeemed
        "
        @redeem="redeemItem"
      />
      <redemption-done
        v-if="redeemed"
        @apply="applyToCart"
        :code="redemptionCode"
        :amount="redemptionAmount"
      ></redemption-done>
      <pwpc-redemption
        v-if="
          (this.$store.state.ew_redeemingPwp ||
            this.$store.state.ew_redeemingPwc) &&
          !redeemed
        "
        @redeem="redeemWithPorC"
      />
      <!-- <redemption-terms
        @redeem="confirmRedemption"
        v-if="
          this.$store.state.ew_checkingTerms &&
          !(this.$store.state.ew_redeemingPwp || this.$store.state.ew_redeemingPwc)
        "
      /> -->
    </div>
  </div>
</template>

<script>
import { getSvg } from "../utils/svgLibrary";
import { replaceValues } from "../utils/formatString";
import RedemptionInfo from "../components/RedemptionCardContent/RedemptionInfo.vue";
import RedemptionDone from "../components/RedemptionCardContent/RedemptionDone.vue";
import PwpcRedemption from "../components/RedemptionCardContent/PwpcRedemption.vue";
// import RedemptionTerms from "../components/RedemptionCardContent/RedemptionTerms.vue";

export default {
  name: "RedemptionCard",
  components: {
    RedemptionInfo,
    RedemptionDone,
    PwpcRedemption,
    // RedemptionTerms,
  },
  data() {
    return {
      pwp: true,
      redeemed: false,
      redemptionCode: null,
      redemptionAmount: null,
    };
  },

  methods: {
    getSvg: getSvg,
    replaceValues: replaceValues,

    // confirmRedemption() {
    //   this.$store.commit("changeTermsState", false);
    //   this.$emit("done");
    // },

    async redeemItem() {
      this.$store.commit("enableMinorLoading");
      this.$store.commit("changeOneRepValue", {
        label: "PUNCHITEMIDVAR",
        value: this.$store.state.ew_selectedRedemption.id,
      });
      this.$store.commit("changeOneRepValue", {
        label: "QRCODEVAR",
        value: this.$store.state.ew_selectedRedemption.code,
      });
      try {
        const response = await fetch(
          replaceValues(
            this.$store.state.ew_redemptionType === "reward"
              ? this.$store.state.ew_integSettings.redeemCatalogueApi
              : this.$store.state.ew_integSettings.redeemOfferApi,
            this.$store.state.ew_repValues,
          ),
          {
            headers: new Headers({
              Authorization: this.$store.state.ew_integSettings.token,
              "Accept-Language": this.$store.state.ew_integSettings.lang,
            }),
          },
        );
        let data = {};
        if (this.$store.state.ew_integSettings.posId == "8") {
          data = JSON.parse(response);
        } else {
          data = await response.json();
        }
        if (!data.status) {
          let error = data;
          throw error;
        }

        this.$store.commit("disableMinorLoading");
        this.redemptionCode = data.code;
        this.redemptionAmount = data.redeem_amount;
        this.redeemed = true;
      } catch (error) {
        this.$store.commit("disableMinorLoading");
        this.$store.commit("showNotification", {
          type: "error",
          title: "Redemption error!",
        });
        setTimeout(() => {
          this.$store.commit("closeNotification");
        }, 5000);
      }
    },

    async redeemWithPorC() {
      this.$store.commit("enableMinorLoading");

      try {
        await this.$root.$emit("mainDataFetch");
        this.$store.commit("disableMinorLoading");
        this.redeemed = true;
      } catch (error) {
        this.$store.commit("disableMinorLoading");
        this.$store.commit("showNotification", {
          type: "error",
          title: "Redemption error!",
        });
        setTimeout(() => {
          this.$store.commit("closeNotification");
        }, 5000);
      }
    },

    async applyToCart() {
      this.$store.commit("changeOneRepValue", {
        label: "COUPONVAR",
        value: this.redemptionCode,
      });

      if (this.$store.state.ew_integSettings.applyCouponPath) {
        window.location = replaceValues(
          this.$store.state.ew_integSettings.applyCouponPath,
          this.$store.state.ew_repValues,
        );
      } else {
        let dataBody = {};
        if (this.$store.state.ew_integSettings.posId == "8") {
          dataBody = JSON.stringify({
            coupon_code: this.redemptionCode,
            remove: 0,
          });
        } else if (this.$store.state.ew_integSettings.posId == "7") {
          dataBody = JSON.stringify({
            coupon: this.redemptionCode,
          });
        }
        await fetch(
          replaceValues(
            this.$store.integSettings.applyCouponApi,
            this.$store.state.ew_repValues,
          ),
          {
            method: "POST",
            body: dataBody,
            headers: new Headers({
              Authorization: this.$store.state.ew_integSettings.token,
              "Accept-Language": this.$store.state.ew_integSettings.lang,
            }),
          },
        );
      }
    },
  },
};
</script>

<style scoped>
.main-kr {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
}

.title-kr {
  font-weight: 700;
  margin-bottom: 18.4px;
}

.main-container-kr {
  border-radius: 12px !important;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 3px 20px #0000001a;
  flex: 1 1 auto;
  background-color: rgba(255, 255, 255, 0.1);
}

.pwpc-container-kr {
}

.container-kr {
  box-shadow: 0px 3px 20px #0000001a;
  padding-left: 17px;
  padding-right: 17px;
  padding-top: 17px;
  padding-bottom: 17px;
}
</style>