<template>
  <div
    :style="cssAtts"
    class="container-kr animate__animated animate__bounceIn"
  >
    <!-- title starts -->
    <div class="title-kr">
      {{ this.$store.state.ew_notification.title }}
    </div>
    <!-- title ends -->
  </div>
</template>

<script>
import { getSvg } from "../utils/svgLibrary";
export default {
  name: "Notification",
  props: ["type", "title", "message"],
  computed: {
    cssAtts() {
      return {
        "--primary_color": "#fff",
        "--bg_color":
          this.$store.state.ew_notification.type === "error"
            ? "#ef5350"
            : this.$store.state.ew_notification.type === "success"
            ? "#616161"
            : "#fbc02d",
      };
    },
  },
  methods: {
    getSvg: getSvg,
  },
};
</script>

<style scoped>
.container-kr {
  background-color: var(--bg_color);
  border-radius: 9999px;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 9999px !important;
  left: 0;
  right: 0;
  width: 50%;
  margin: auto;
  z-index: 50;
}

.title-kr {
  color: var(--primary_color);
  display: flex;
  align-items: center;
}
</style>