<template>
  <div class="main-kr">
    <!-- title starts -->
    <div class="title-kr">
      {{ this.$store.state.ew_selectedWte.title }}
    </div>
    <!-- title ends -->
    <div class="container-kr">
      <div
        class="icon-kr ew_icon-ew_bg-primary-kr"
        v-html="
          getSvg(
            this.$store.state.ew_settings.iconPrimaryColor,
            34,
            34,
            this.$store.state.ew_selectedWte.icon,
          )
        "
      ></div>
      <div
        style="margin-top: 16px"
        v-html="this.$store.state.ew_selectedWte.description"
      ></div>
    </div>
  </div>
</template>

<script>
import { getSvg } from "../utils/svgLibrary";

export default {
  name: "RedemptionCard",

  methods: {
    getSvg: getSvg,
  },
};
</script>

<style scoped>
.main-kr {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
}

.title-kr {
  margin-bottom: 18.4px;
  font-weight: 700;
}

.container-kr {
  border-radius: 12px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 1 auto;
  background-color: rgba(255, 255, 255, 0.15);
  box-shadow: 0px 3px 20px #0000001a;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  padding-bottom: 16px;
}

.icon-kr {
  display: flex;
  justify-content: center;
  align-items: center;
  border-style: solid;
  border-radius: 9999px !important;
  height: 64px;
  width: 64px;
  padding: 4px;
  margin-top: 12px;
}
</style>